import { FpjsProvider } from "@fingerprintjs/fingerprintjs-pro-react";
import "@material/react-text-field/dist/text-field.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import GuidedTourProvider from "contexts/GuidedTourContext";
import { appWithTranslation } from "next-i18next";
import Script from "next/script";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import TagManager from "react-gtm-module";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { isProduction } from "../components/tools";
import { AlertAttentionProvider } from "../contexts/AlertAttentionContext";
import { AlertContextProvider } from "../contexts/AlertContext/parent";
import { AuthProvider } from "../contexts/AuthContext";
import { ConstantsProvider } from "../contexts/ConstantsContext/parent";
import { DimensionProvider } from "../contexts/DimensionContext";
import { FilePreviewProvider } from "../contexts/FilePreviewContext/parent";
import { GoogleDrivePreviewProvider } from "../contexts/GoogleDrivePreviewContext/parent";
import { InvoiceProvider } from "../contexts/InvoiceContext";
import { LayoutProvider } from "../contexts/Layout/parent";
import TaskProvider from "../contexts/TaskContext/parent";
import { TimerProvider } from "../contexts/TimerContext";
import { ToasterProvider } from "../contexts/ToasterContext";
import TranslationProvider from "../contexts/TranslationContext/parent";
import ErrorBoundary from "../errorBoundaries";
import SEOComponents from "../pageComponents/script/seo";
import "../styles/badge.css";
import "../styles/button.css";
import "../styles/draftjs.css";
import "../styles/globals.css";
import "../styles/icon.css";
import "../styles/input.css";
import "../styles/modals.css";
import "../styles/sidebar.css";
import "../styles/slick.css";
import "../styles/summarydetails.css";
import "../styles/table.scss";
import "../styles/text.css";
import "../styles/toaster.css";
import "../styles/tooltip.css";
import "../styles/transition.css";
import { initEvents } from "../universalFunctions/events";
import { useRouter } from "next/router";

const OpenReplay = ({ children }) => {
  const __html = `
  var initOpts = {
    projectKey: "pLvbuEtgrgC1obMgFoLj",
    ingestPoint: "https://openreplay.transfez-jack-engineering.xyz/ingest",
    defaultInputMode: 1,
    obscureTextNumbers: true,
    obscureTextEmails: true,
    network: {
      capturePayload: true,
      ignoreHeaders: true,
      sanitizer: (res) => {
        const rawObj = JSON.parse(res.request.body);
        let obj = {};

        const keys = Object.keys(rawObj);

        keys.forEach((key) => {
          const isPassword = key.includes("pass");
          const isPin = key.includes("pin");

          const isPass = !isPassword && !isPin;

          if (!isPass) return;
          obj[key] = rawObj[key];
        });

        res.request.body = obj;
        return res;
      },
    },
  };
  var startOpts = { userID: "" };
  (function(A,s,a,y,e,r){
    r=window.OpenReplay=[e,r,y,[s-1, e]];
    s=document.createElement('script');s.src=A;s.async=!a;
    document.getElementsByTagName('head')[0].appendChild(s);
    r.start=function(v){r.push([0])};
    r.stop=function(v){r.push([1])};
    r.setUserID=function(id){r.push([2,id])};
    r.setUserAnonymousID=function(id){r.push([3,id])};
    r.setMetadata=function(k,v){r.push([4,k,v])};
    r.event=function(k,p,i){r.push([5,k,p,i])};
    r.issue=function(k,p){r.push([6,k,p])};
    r.isActive=function(){return false};
    r.getSessionToken=function(){};
  })("//static.openreplay.com/13.0.0/openreplay-assist.js",1,0,initOpts,startOpts);
`;

  const inactiveArr = ["internal", "localhost"];

  const isActive = !inactiveArr.find((string) =>
    window.location.href.includes(string)
  );

  return (
    <>
      {isActive && (
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html,
          }}
        />
      )}
      {children}
    </>
  );
};

const MyApp = ({ Component, pageProps }) => {
  useEffect(initEvents, []);

  const __html = `(function(i, s, o, g, r, a, m, n) {
    i.moengage_object = r;
    t = {};
    q = function(f) {
      return function() {
        (i.moengage_q = i.moengage_q || []).push({ f: f, a: arguments });
      };
    };
    (f = [
      "track_event",
      "Web_Start_your_application",
      "Web_Signup",
      "Web_Want_BRE1_limitincrease_click",
      "Web_Happy_with_BRE1limit_click",
      "Web_Complete_Profile",
      "Web_On_bank_statement",
      "Web_Bank statement click_perfios",
      "Web_Bank statement click_manual",
      "Web_Suspend_bank_statement_perfios",
      "Web_Suspend_bank_statement_manual",
      "Web_Suspend_salaryslip",
      "Web_Smart_Repay_Click",
      "Web_downloadapp",
      "destroy_session",
      "add_unique_user_id",
      "moe_events",
      "call_web_push",
      "track",
      "location_type_attribute"
    ]),
      (h = { onsite: ["getData"] });
    for (k in f) {
      t[f[k]] = q(f[k]);
    }
    a = s.createElement(o);
    m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
    i.moe =
      i.moe ||
      function() {
        n = arguments[0];
        return t;
      };
    a.onload = function() {
      if (n) {
        i[r] = moe(n);
      }
    };
  })(
    window,
    document,
    "script",
    "https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js",
    "Moengage"
  );
  Moengage = moe({
    app_id: "YBYJM6XZIM209SM2ZJQ3DFOK",
    debug_logs: 1,
    swPath: "/serviceworker.js",
    swScope: '/'
  });`;

  const tagManagerArgs = { gtmId: "GTM-567HLBD" };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  const { query, pathname, locale, replace } = useRouter();

  useEffect(() => {
    if (process.env.NODE_ENV === "development") return;

    if (locale != "id") return;
    replace(pathname, query, { locale: false });
  }, [pathname, locale]);

  // useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker
  //       .register('/serviceworker.js')
  //       .then((registration) => console.log('scope is: ', registration.scope));
  //   }
  // }, []);

  return (
    <FpjsProvider
      loadOptions={{
        apiKey: process.env.NEXT_PUBLIC_FINGERPRINT,
        endpoint: ["https://ZhQbh9K4tf9t.itsjack.com", { default: "endpoint" }],
        scriptUrlPattern: [
          "https://ZhQbh9K4tf9t.itsjack.com/web/v<version>/<apiKey>/loader_v<loaderVersion>.js",
          "https://fpnpmcdn.net/v<version>/<apiKey>/loader_v<loaderVersion>.js",
        ],
        region: "ap",
      }}
    >
      <TranslationProvider>
        <DimensionProvider>
          <ToasterProvider>
            <AuthProvider>
              <AlertContextProvider>
                <InvoiceProvider>
                  <AlertAttentionProvider>
                    <TimerProvider>
                      <ConstantsProvider>
                        <GoogleDrivePreviewProvider>
                          <TaskProvider>
                            <FilePreviewProvider>
                              <SEOComponents />

                              {isProduction && (
                                <Script
                                  strategy="afterInteractive"
                                  dangerouslySetInnerHTML={{
                                    __html,
                                  }}
                                />
                              )}
                              <LayoutProvider>
                                <ErrorBoundary>
                                  <GuidedTourProvider>
                                    <OpenReplay>
                                      <Component {...pageProps} />
                                    </OpenReplay>
                                  </GuidedTourProvider>
                                </ErrorBoundary>
                              </LayoutProvider>
                            </FilePreviewProvider>
                          </TaskProvider>
                        </GoogleDrivePreviewProvider>
                      </ConstantsProvider>
                    </TimerProvider>
                  </AlertAttentionProvider>
                </InvoiceProvider>
              </AlertContextProvider>
            </AuthProvider>
          </ToasterProvider>
        </DimensionProvider>
      </TranslationProvider>
    </FpjsProvider>
  );
};

export default appWithTranslation(MyApp);
