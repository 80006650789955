import styles from "./styles.module.css";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { useModalHook } from "../../../components/Modals";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { NotificationIcon } from "../../../pageComponents/notifications/components";
import { NotificationModal } from "../../../pageComponents/notifications/parent";
import PreventionModal from "../../../pageComponents/subscriptionsJackComponents/general-components/PreventionModal/parent";
import { useSubscriptionUsage } from "../../../pageComponents/subscriptionsJackComponents/logics/general-hooks";
import { eventsTracker } from "../../../universalFunctions/events";
import { useClickOutside } from "../../../universalFunctions/useClickOutside";
import {
  BILL_VALUE,
  CARD_VALUE,
  CROSS_BORDER_VALUE,
  JACK_TRANSFER_VALUE,
  LOCAL_TRANSFER_VALUE,
  PAYROLL_VALUE,
  REIMBURSEMENT_VALUE,
  VA_VALUE,
  getUserButtonBooleans,
  getUserRole,
  useActiveModules,
} from "../../AuthContext";
import { useConstants } from "../../ConstantsContext/parent";
import { useLayout } from "../parent";
import { isProduction } from "../../../components/tools";
import { snakeCase } from "lodash";
import TransferSelectionModal from "../../../modals/TransferSelectionModal";
import { J2J_EVENT_CLICK_TRANSFER_BALANCE } from "../../../pageComponents/jackTransferComponents/constants";
import NewTag from "../../../components/NewTag";
import CustomHoverInfo from "components/CustomHoverInfo/CustomHoverInfo";
import TemporaryUnavailableTooltipWrapper from "components/TemporaryUnavailableTooltipWrapper";

export const CreateAndBellHeader = ({ style = {} }) => {
  const [preventionModalType, setPreventionModalType] = useState("");

  const { push, replace } = useRouter();

  const {
    invoice_qty_left,
    reimbursement_qty_left,
    isSeedPlan,
    isSuspended,
    loadingSubscriptionUsage,
  } = useSubscriptionUsage();

  const isSeedV2Invoice = isSeedPlan && invoice_qty_left != null;
  const isSeedV2Reimbursement = isSeedPlan && reimbursement_qty_left != null;
  const isReachedInvoiceLimit = isSeedV2Invoice && invoice_qty_left === 0;
  const isReachedReimbursementLimit =
    isSeedV2Reimbursement && reimbursement_qty_left === 0;

  const { isOpen, toggle, close } = useModalHook();
  const {
    isOpen: isOpenPreventionModal,
    open: openPreventionModal,
    close: closePreventionModal,
  } = useModalHook();
  const {
    isOpen: isOpenTransferSelectionModal,
    open: openTransferSelectionModal,
    close: closeTransferSelectionModal,
  } = useModalHook();

  const { canCreateCard, canCreateVA } = getUserButtonBooleans();
  const canSeeFeatures = canCreateVA || canCreateCard;

  const {
    isDrafterInvoice,
    isDrafterReimbursement,
    isDrafterPayroll,
    isDrafterSendMoney,
    isAdminOrSuperAdmin,
    isDrafterBalanceManagement,
  } = getUserRole();

  const handlePreventionModal = (type = "invoice") => {
    setPreventionModalType(type);
    openPreventionModal();
  };

  const handleClick = (value) => {
    if (loadingSubscriptionUsage) return;

    const isInvoice = value.includes("invoice");
    const isReimbursement = value.includes("reimbursement");
    const isSendMoney = value === "send_money";

    const isPreventCreateInvoice = isInvoice && isReachedInvoiceLimit;
    const isPreventCreateReimbursement =
      isReimbursement && isReachedReimbursementLimit;

    if (isSendMoney) {
      eventsTracker(J2J_EVENT_CLICK_TRANSFER_BALANCE, {
        parameters: { page_title: "dashboard" },
        value: "dashboard",
      });
      return openTransferSelectionModal();
    }
    if (isPreventCreateInvoice) return handlePreventionModal("invoice");
    if (isPreventCreateReimbursement)
      return handlePreventionModal("reimbursement");
    const pathWoQuery = value.split("?")[0];
    eventsTracker(`topbar_create_to_${snakeCase(pathWoQuery)}`);
    push(value);
  };

  const {
    isActiveInternationalTransfer,
    isActiveLocalTransfer,
    isActiveReimbursement,
    isActiveBillPayment,
    isActivePayroll,
    isActiveCard,
    isActiveVA,
  } = useActiveModules();

  const array = [
    {
      title: "Transfer",
      array: [
        isDrafterBalanceManagement && {
          label: "Between Jack",
          iconName: "swap-outline",
          value: JACK_TRANSFER_VALUE,
          isNew: true,
        },
        isDrafterSendMoney && {
          label: "International Transfer",
          iconName: "international-transfer",
          value: CROSS_BORDER_VALUE,
          isDisabled: !isActiveInternationalTransfer,
        },
        isDrafterSendMoney && {
          label: "Local Transfer",
          iconName: "send-money",
          value: LOCAL_TRANSFER_VALUE,
          isDisabled: !isActiveLocalTransfer,
        },
      ],
    },
    {
      title: "Payables",
      array: [
        isDrafterReimbursement && {
          label: "Reimbursement",
          iconName: "Reimbursement",
          value: REIMBURSEMENT_VALUE,
          isDisabled: !isActiveReimbursement,
        },
        isDrafterInvoice && {
          label: "Bill Payment",
          iconName: "Invoice Payment",
          value: BILL_VALUE,
          isDisabled: !isActiveBillPayment,
        },
        isDrafterPayroll && {
          label: "Payroll",
          iconName: "payroll",
          value: PAYROLL_VALUE,
          isDisabled: !isActivePayroll,
        },
      ],
    },
    canSeeFeatures && {
      title: "Features",
      array: [
        canCreateCard && {
          label: "New Card",
          value: CARD_VALUE,
          iconName: "add-card",
          eventsName: "card_navigate_to_create_card",
          isDisabled: !isActiveCard,
        },
        canCreateVA && {
          label: "Virtual Account",
          value: VA_VALUE,
          iconName: "coin",
          eventsName: "go_to_va_create_from_header_create_btn",
          isDisabled: !isActiveVA,
        },
      ],
    },
    {
      array: [{ label: "Draft", value: "/draft", iconName: "archive-outline" }],
    },
  ].filter((item) => item);

  const {
    isAllowOverflow,
    showInactiveModuleTooltip,
    handleHoverModuleOption,
    handleUnhoverModuleOption,
  } = useActiveModules({ isOpenCreateDropdown: isOpen });

  const ref = useRef();
  const { clientWidth = 0 } = ref?.current || {};

  const { scrollTop } = useLayout();

  useEffect(close, [scrollTop]);

  useClickOutside({ ref, clickOutside: close });

  const [refChild, setRefChild] = useState({});
  const padding = 8;
  const componentHeight = (refChild?.clientHeight || 0) + padding * 2;

  const handleClickCreate = () => {
    if (isSuspended) {
      setPreventionModalType("suspended");
      openPreventionModal();
      return;
    }

    eventsTracker("topbar_create_open_dropdown");
    toggle();
  };

  const {
    // counterTotal: totalUnread,
    refetchTotal,
    isOpen: isOpenNotif,
    toggle: toggleNotif,
    close: closeNotif,
    showCounter,
    setShowCounter,
    totalUnread,
  } = useConstants();

  const handleClickBell = () => {
    eventsTracker("click_bell_notif");
    toggleNotif();
    setShowCounter(false);
    // setDataTotal(0)
  };

  // const {
  //   isOpen: isOpenNotif,
  //   toggle: toggleNotif,
  //   close: closeNotif,
  // } = useModalHook();

  const handleCloseNotif = () => {
    const newQuery = {};
    replace({
      query: {
        ...newQuery,
      },
    });
    refetchTotal();
    closeNotif();
  };

  useEffect(() => {
    refetchTotal();

    const intervalId = setInterval(() => {
      refetchTotal();
    }, 20000); // 20 seconds in milliseconds

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      className="d-flex justify-content-end align-items-center"
      style={{
        paddingRight: 32,
        paddingTop: 16,
        paddingBottom: 24,
        ...style,
        gap: 16,
      }}
    >
      <JackIcons
        name="help"
        fill={jackColors.black34}
        onClick={() => {
          eventsTracker("topbar_create_open_commandbar");
          window.CommandBar.open();
        }}
        style={{ height: 20, width: 20 }}
        className="hover"
      />
      {/* <JackIcons
        name="bell"
        fill={jackColors.black34}
        style={{ height: 20, width: 20 }}
        onClick={handleClickBell}
        className="hover"
      /> */}
      <NotificationIcon
        handleClickBell={handleClickBell}
        counter={totalUnread}
        showCounter={showCounter}
      />
      <div ref={ref}>
        <ButtonJack
          leftIcon={
            <JackIcons
              name="plus-outline"
              fill={
                loadingSubscriptionUsage
                  ? jackColors.neutral700
                  : jackColors.black34
              }
            />
          }
          onClick={handleClickCreate}
          isLoading={loadingSubscriptionUsage}
        >
          Create
        </ButtonJack>
        <div
          style={{
            backgroundColor: "white",
            boxShadow: "0px 8px 16px -4px rgba(22, 34, 51, 0.08)",
            borderRadius: 8,
            width: 256,
            position: "fixed",
            marginLeft: clientWidth - 256,
            padding: 8,
            zIndex: 10,
            height: isOpen ? componentHeight : 0,
            overflow: isAllowOverflow ? "visible" : "hidden",
            transition: "all 0.4s linear",
            visibility: isOpen ? "initial" : "hidden",
          }}
        >
          <div ref={(e) => setRefChild(e)}>
            {array.map((item, index) => {
              const { title, array } = item;

              if (!array.length) return null;

              return (
                <div key={index}>
                  <GothamMedium
                    style={{ padding: 8, color: jackColors.black34 }}
                  >
                    {title}
                  </GothamMedium>
                  <div
                    className="w-100"
                    style={{ height: 1, backgroundColor: jackColors.greyE6 }}
                  />

                  {array.map((item, index) => {
                    if (!item) return null;

                    const {
                      label,
                      value,
                      iconName,
                      eventsName,
                      isNew,
                      isDisabled,
                    } = item;

                    return (
                      <TemporaryUnavailableTooltipWrapper
                        key={`${value}-${index}`}
                        module={value}
                        wrapperProps={{
                          className: styles.option,
                          style: {
                            cursor: isDisabled ? "not-allowed" : "pointer",
                          },
                          onClick: () => {
                            if (isDisabled) return;
                            handleClick(value);
                            eventsName && eventsTracker(eventsName);
                          },
                        }}
                        activeModulesProps={{
                          showInactiveModuleTooltip,
                          handleHoverModuleOption,
                          handleUnhoverModuleOption,
                        }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            padding: 10,
                            paddingLeft: 8,
                            paddingRight: 8,
                            gap: 8,
                          }}
                        >
                          <JackIcons
                            name={iconName}
                            fill={
                              isDisabled
                                ? jackColors.neutral600
                                : jackColors.grey6C
                            }
                          />
                          <GothamRegular
                            woFontColor
                            style={{
                              color: isDisabled
                                ? jackColors.neutral600
                                : jackColors.neutral900,
                            }}
                          >
                            {label}
                          </GothamRegular>
                          {isNew && <NewTag />}
                          {isDisabled && (
                            <JackIcons
                              name="alert-circle"
                              fill={jackColors.yellowFC}
                              style={{
                                scale: "0.8",
                                position: "absolute",
                                right: "8px",
                                top: "10px",
                              }}
                            />
                          )}
                        </div>
                      </TemporaryUnavailableTooltipWrapper>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <PreventionModal
        type={preventionModalType}
        isOpen={isOpenPreventionModal}
        toggle={closePreventionModal}
        onClick={() => {
          if (preventionModalType === "suspended")
            return closePreventionModal();
          push("/plans/showcase");
        }}
      />
      <NotificationModal isOpen={isOpenNotif} toggle={handleCloseNotif} />
      <TransferSelectionModal
        isOpen={isOpenTransferSelectionModal}
        toggle={closeTransferSelectionModal}
      />
    </div>
  );
};
