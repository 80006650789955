import { titleCase } from "change-case";
import { useEffect, useState } from "react";
import Select from "react-select";
import { colors, jackColors } from "../../assets/colors";
import {
  phoneCode,
  phoneSimpleFormatter,
  phoneSimpleLabelSplitter,
} from "../../assets/phoneCode";
import { Flag } from "../Flag";
import { GothamRegular } from "../Text";
import { isEmpty } from "lodash";
import { JackIcons } from "../../assets/jackIcons/parent";

const stylesFunc = (isError, inputStyle, containerStyle) => ({
  control: (style, { isFocused }) => ({
    ...style,
    borderRadius: "4px",
    height: "40px",
    fontSize: "14px",
    fontFamily: "GothamBook",
    borderColor: isFocused
      ? colors.neutral900
      : isError
      ? jackColors.redE7
      : colors.neutral500,
    boxShadow: "none",
    "&:hover": {
      border: `1px solid ${jackColors.neutral600}`,
    },
    marginBottom: "32px",
    ...containerStyle,
  }),
  input: (style) => ({
    ...style,
    fontFamily: "GothamBook",
    ...inputStyle,
  }),
  option: (style, { isSelected, isFocused }) => ({
    ...style,
    fontSize: "14px",
    fontFamily: "GothamBook",
    color: colors.grey6c,
    backgroundColor: isSelected
      ? colors.greye6
      : isFocused
      ? jackColors.greyF1
      : "transparent",
  }),
  menu: (style) => ({
    ...style,
    border: `1px solid ${colors.greye6}`,
    borderRadius: "4px",
  }),
  indicatorSeparator: () => ({}),
  dropdownIndicator: (style) => ({
    ...style,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "GothamBook",
    color: "#BBBBC0",
  }),
});

export const SelectionJackState = ({
  onChange,
  options,
  label,
  value,
  isError,
  inputStyle,
  className = "",
  placeholder = "",
  containerStyle,
  components,
  customComponents = null,
  disabled = false,
  required = false,
  divStyle,
  ...props
}) => {
  const isObjVal = typeof value == "object";

  return (
    <div style={divStyle}>
      <GothamRegular
        className="font12"
        style={{
          color: isError ? jackColors.redE7 : colors.neutral900,
          marginBottom: 8,
        }}
      >
        {label}
        {required && <span style={{ color: "red" }}>*</span>}
      </GothamRegular>
      <Select
        {...props}
        components={
          !isEmpty(customComponents)
            ? { ...customComponents }
            : { ...components, DropdownIndicator }
        }
        className={className}
        instanceId="custom-react-select"
        styles={stylesFunc(isError, inputStyle, containerStyle)}
        options={options}
        placeholder={placeholder}
        onChange={(item) => onChange(item?.value)}
        value={
          isObjVal
            ? value
            : options.filter(({ value: valueItem }) => valueItem == value)[0]
        }
        isDisabled={disabled}
      />
    </div>
  );
};

export const DropdownIndicator = (props) => {
  const { isFocused } = props;
  return (
    <JackIcons
      name="chevron-down"
      fill={isFocused ? jackColors.black34 : jackColors.greyBB}
      style={{
        width: 20,
        height: 20,
        marginRight: 8,
      }}
    />
  );
};
export const SelectionJack = ({
  useFormObj,
  name,
  label: labelProps,
  noLabel = false,
  options,
  containerStyle,
  inputStyle,
  className,
  placeholder,
  required = true,
  defaultValue = null,
  onChange,
  error,
  components,
  helperText,
  asterixRequired = false,
  divStyle,
  customLabelStyle,
  customStyleFunc = null,
  woAsterisk,
  ...props
}) => {
  const { register, setValue, watch, errors } = useFormObj;
  const [isError, setIsError] = useState(errors?.[name] || error);
  const val = watch(name) || defaultValue;

  const value = isEmpty(val) ? null : val;

  const label = noLabel ? "" : labelProps || titleCase(name);

  useEffect(() => {
    register(name, { value });
  }, [name, register]);

  useEffect(() => {
    if (value) setIsError(false);
    else setIsError(errors?.[name] || error);
  }, [errors, error, value]);

  const styles = customStyleFunc ? customStyleFunc : stylesFunc;
  return (
    <div style={divStyle}>
      {!noLabel && (
        <GothamRegular
          className="font12"
          style={{
            color: isError ? jackColors.redE7 : colors.neutral900,
            marginBottom: 8,
            ...customLabelStyle,
          }}
        >
          {label}{" "}
          {(asterixRequired || required) && !woAsterisk && (
            <span style={{ color: colors.redE7 }}>*</span>
          )}
        </GothamRegular>
      )}
      <Select
        {...props}
        components={{ ...components, DropdownIndicator }}
        placeholder={placeholder}
        className={className}
        instanceId="custom-react-select"
        styles={styles(isError, inputStyle, containerStyle)}
        options={options}
        name={name}
        onChange={(item) => {
          setValue(name, item);
          onChange && onChange(item);
        }}
        value={value}
      />
      <div
        style={{
          marginTop: helperText || isError ? "-28px" : "4px",
          marginBottom: "32px",
        }}
      >
        {helperText && (
          <GothamRegular
            woFontColor
            className="font10"
            style={{
              color: jackColors.neutral700,
            }}
          >
            {helperText}
          </GothamRegular>
        )}
        {isError && (
          <GothamRegular
            woFontColor
            className="font10"
            style={{
              color: jackColors.redE7,
            }}
          >
            {isError?.message}
          </GothamRegular>
        )}
      </div>
    </div>
  );
};

export const PhoneSelectionJack = ({
  containerStyle,
  useFormObj,
  name,
  defaultValue: defaultValueProp = "",
  ...props
}) => {
  const options = phoneSimpleFormatter(phoneCode);
  const { watch, setValue } = useFormObj;
  const defaultValue = {
    label: "Indonesia-+62-ID",
    value: "ID",
  };

  useEffect(() => {
    setValue(
      name,
      defaultValueProp
        ? options.find(({ value }) => value === defaultValueProp)
        : defaultValue
    );
  }, [defaultValueProp, name]);

  const value = watch(name)?.value;

  const IndicatorSeparator = () => {
    return (
      <div
        className="d-flex align-items-center"
        style={{
          position: "absolute",
          left: "12px",
          width: "80%",
        }}
      >
        <Flag iso={value} />
      </div>
    );
  };

  const PhoneSimpleSingleValue = ({ selectProps }) => {
    const { number } = phoneSimpleLabelSplitter(selectProps?.value?.label);

    return (
      <div
        className="d-flex align-items-center"
        style={{ position: "absolute", bottom: 10, left: 8 }}
      >
        <span
          style={{
            color: colors.grey25,
            fontFamily: "GothamBook",
            fontSize: "14px",
            marginBottom: "-3px",
            marginLeft: "36px",
          }}
        >
          {number}
        </span>
      </div>
    );
  };

  const PhoneSimpleOptions = (props) => {
    const { innerProps, isSelected, data } = props || {};
    const { number } = phoneSimpleLabelSplitter(data?.label);

    const fontColor = isSelected ? colors.neutral100 : colors.neutral900;
    return (
      <div
        className="d-flex justify-content-between align-items-center darkhover"
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: isSelected ? colors.neutral900 : "",
          borderRadius: "0px",
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 8,
        }}
        {...innerProps}
      >
        <GothamRegular style={{ color: fontColor }}>{number}</GothamRegular>
      </div>
    );
  };

  return (
    <SelectionJack
      options={options}
      components={{
        IndicatorSeparator,
        SingleValue: PhoneSimpleSingleValue,
        Option: PhoneSimpleOptions,
        DropdownIndicator,
      }}
      useFormObj={useFormObj}
      name={name}
      containerStyle={{
        width: "110px",
        ...containerStyle,
      }}
      inputStyle={{
        marginLeft: "36px",
      }}
      defaultValue={defaultValue}
      {...props}
    />
  );
};
