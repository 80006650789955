import { useRouter } from "next/router";
import React from "react";
import { jackColors } from "../../assets/colors";
import CrossborderAvatar from "../../assets/images/crossborder-single-avatar.svg";
import { JackIcons } from "../../assets/jackIcons/parent";
import { Avatar } from "../../components/Avatar";
import { ButtonJack } from "../../components/ButtonsJack/parent";
import { booleanState } from "../../components/Status";
import { GothamMedium, GothamRegular } from "../../components/Text";
import {
  FullDateFormatter,
  formatCurrencyNoDecimal,
  useExpiredCountDownDetails,
} from "../../components/tools";
import { DetailsModal } from "../../modals/smartActivityModal/crossBorderSingleModal/components";
import { getBeneficiaryInternational } from "../../modals/smartActivityModal/crossBorderSingleModal/logics";
import { CrossBorderSingleStatusLine } from "../../modals/smartActivityModal/crossBorderSingleModal/status";
import { useAlertDecider } from "../crossBorderSingleConfirmation";
import { CountDownTopUpModal } from "../homeComponents/topup/components";
import { expiresFormatter } from "../homeComponents/topup/logic";
import SuccessAdvertisement from "../subscriptionsJackComponents/general-components/SuccessAdvertisement/parent";

export const InternationalSingleSuccess = ({ data }) => {
  const {
    approval_tasks,
    total_trx_amount,
    sent_amount,
    fee,
    state,
    payment_channel,
    payment_expiry_at,
  } = data || {};

  const { beneficiaryName: name } = getBeneficiaryInternational(data);

  const headerObj = {
    title: "International Transfer",
    desc: `Transfer to ${name}`,
    icon: CrossborderAvatar,
  };

  const { isValidatingPayment } = booleanState(state);
  const submittedData =
    approval_tasks?.length && approval_tasks[0]?.state === "requested";
  const isWaitingApproval =
    state === "waiting_for_approval_tasks" || state === "waiting_for_approval";
  const isRejected = state === "approval_rejected";
  const isCancelled = state === "cancelled" || state === "canceled";
  // For Data Countdown time
  const { time, dataForCountDown } = useExpiredCountDownDetails({
    payment_expiry_at,
  });

  const { push } = useRouter();
  const CanCreateAnother = submittedData || isCancelled;

  const onClick = ({ type }) => {
    const isCreate = type === "create";
    if (isCreate) return push("/cross-border");
    return push("/dashboard");
  };

  const TitleDecider = () => {
    if (submittedData) return "You have submitted an international transfer!";
    if (isValidatingPayment) return "Your money is on its way!";
    if (isWaitingApproval) return "You have approved this request!";
    if (isRejected) return "This request has been rejected";
    if (isCancelled) return "This request has been canceled";
    return "";
  };

  const DescDecider = () => {
    if (submittedData)
      return `We'll let you know once your request has been reviewed.`;
    if (isValidatingPayment)
      return "We'll notify you as soon as the transfer is complete. Please take a break and wait for further information.";
    return "";
    // return ``;
  };

  const IconDecider = () => {
    if (isRejected || isCancelled) return "close-circle";
    return "success-icon";
  };

  const array = [
    // {
    //   title: "Total amount",
    //   value: total_trx_amount,
    // },
    // {
    //   title: "Handling fee",
    //   value: `IDR ${formatCurrencyNoDecimal(fee)}`,
    // },
    // {
    //   spacer: true,
    // },
    {
      title: "Settlement method",
      value: useAlertDecider({ data: payment_channel }),
    },
    {
      title: "Final amount",
      value: `IDR ${formatCurrencyNoDecimal(sent_amount)}`,
      rightBold: true,
    },
  ];

  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: 438,
        position: "relative",
        paddingBottom: 8,
      }}
    >
      <div className="text-center">
        <JackIcons
          name={IconDecider()}
          fill="#343434"
          style={{ width: 60, height: 60 }}
        />
        <GothamMedium className="font24" style={{ margin: "32px 0px 8px 0px" }}>
          {TitleDecider()}
        </GothamMedium>
        <GothamRegular style={{ color: jackColors.grey6C, marginBottom: 32 }}>
          {DescDecider()}
        </GothamRegular>
      </div>
      {Boolean(submittedData) && (
        <div style={{ marginBottom: 32 }}>
          <CountDownTopUpModal time={time} data={dataForCountDown} />
        </div>
      )}
      <div style={{ marginBottom: 32 }}>
        <CardListSuccess data={data} array={array} headerObj={headerObj} />
      </div>
      <CrossBorderSingleStatusLine transaction={data} isModal={false} />
      <SuccessAdvertisement type="international-transfer" />

      <div style={{ margin: "32px 0px" }}>
        {CanCreateAnother && (
          <ButtonJack
            style={{ width: "100%", marginBottom: 16 }}
            onClick={() => onClick({ type: "create" })}
          >
            Create Another
          </ButtonJack>
        )}
        <ButtonJack
          type={CanCreateAnother ? "outline" : "filled"}
          style={{ width: "100%" }}
          onClick={() => onClick({ type: "close" })}
        >
          Close
        </ButtonJack>
      </div>
    </div>
  );
};

export const CardListSuccess = ({ headerObj, isAfterRelease, woHr, array }) => {
  const {
    title = "title",
    desc = "description",
    icon = CrossborderAvatar,
    jackIcons = false,
  } = headerObj || {};

  return (
    <div className="borderE6" style={{ padding: 16 }}>
      <div className="d-flex" style={{ position: "relative" }}>
        {!!jackIcons ? (
          <Avatar
            mainIcon={<JackIcons name={jackIcons} fill="#FFF" />}
            size="medium"
            style={{ marginRight: 8 }}
          />
        ) : (
          <img src={icon} alt="icon" className="mr-2" />
        )}
        <div>
          <GothamMedium className="font12">{title}</GothamMedium>
          <GothamRegular
            className="font12"
            style={{ color: jackColors.neutral700 }}
          >
            {desc}
          </GothamRegular>
        </div>
      </div>
      {woHr ? (
        <></>
      ) : (
        !isAfterRelease && <hr style={{ margin: "11px 0px 16px 0px" }} />
      )}
      <DetailsModal
        array={array}
        containerStyle={{ padding: 0, border: "0px solid transparent" }}
      />
    </div>
  );
};
