import router from "next/router";
import React, { useEffect, useState } from "react";
import { useForm } from "@tools";
import { Modal } from "reactstrap";
import { jackColors } from "../../assets/colors";
import dummyPdf from "../../assets/images/dummy-pdf.svg";
import greyDocument from "../../assets/images/grey-doc.svg";
import ModalIcon from "../../assets/images/invoice-payment-modal.svg";
import lightBulbIcon from "../../assets/images/lightbulb.svg";
import warningPink from "../../assets/images/warning-pink.svg";
import xIcon from "../../assets/images/x-icon.svg";
import { JackIcons } from "../../assets/jackIcons/parent";
import { CustomButton } from "../../components/Buttons";
import { ButtonJack } from "../../components/ButtonsJack/parent";
import {
  BackHeader,
  CloseHeader,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../components/CardModal";
import { FileInput } from "../../components/inputs";
import { FileInputJack } from "../../components/inputs/fileInputs/fileInput";
import { LeftRightList } from "../../components/Lists";
import { TopUpModal } from "../../components/Modals";
import {
  GothamMedium,
  TextInlineBold,
  TextInlineMedium,
  TypeFezNeueBold,
  TypeFezNeueLight,
  TypeFezNeueMedium,
  TypeFezNeueRegular,
} from "../../components/Text";
import {
  formatCurrency,
  schemaGenerator,
  windowDimension,
} from "../../components/tools";
import { useGetAuth } from "../../contexts/AuthContext";
import { ToasterHook } from "../../contexts/ToasterContext";
import { CenterendIconLocalTransfer } from "../../modals/smartActivityModal/localDisbursementBatchModal/transactionModal";
import { apiBusiness, useMutation } from "../../tools/api";
import { eventsTracker } from "../../universalFunctions/events";
import { CloseButton } from "./createPage/uploadPage";
import { pluralize } from "./data/formatter";
import { useSubscriptionUsage } from "../subscriptionsJackComponents/logics/general-hooks";
import CompanyLimit from "../subscriptionsJackComponents/general-components/CompanyLimit/parent";
import UpgradeLimitBanner from "../subscriptionsJackComponents/general-components/UpgradeLimitBanner/parent";
import OverLimit from "../subscriptionsJackComponents/general-components/OverLimit/parent";

export const InsufficientBalanceModal = ({
  isOpen = false,
  amountToPay = 0,
  toggle = () => {},
  handleClick = () => {},
}) => {
  const [middleHeight, setMiddleHeight] = useState(0);
  const [openTopUpModal, setOpenTopUpModal] = useState(false);
  const { user } = useGetAuth();
  const { balance = 0 } = user?.mainBalance || {};
  const array = [
    {
      left: "Available balance",
      right: `IDR ${formatCurrency(balance)}`,
    },
    {
      left: "Amount to pay",
      right: `IDR ${formatCurrency(amountToPay)}`,
    },
  ];
  const array2 = [
    {
      left: "You need to top up",
      right: `IDR ${formatCurrency(Number(amountToPay) - balance)}`,
    },
  ];
  return (
    <>
      <RightCardModalAtomic
        isOpen={isOpen}
        toggle={toggle}
        hideModal={openTopUpModal}
        header={
          <div style={{ color: "#FCFCFC", marginBottom: 24 }}>
            <BackHeader isMedium={true} toggle={toggle} />
            <TypeFezNeueMedium
              style={{
                fontSize: 24,
                lineHeight: "32px",
                marginTop: 16,
                marginBottom: 4,
                color: "white",
              }}
            >
              You Don't have enough balance
            </TypeFezNeueMedium>
            <TypeFezNeueLight
              style={{
                lineHeight: "20px",
                color: "white",
              }}
            >
              To complete your transaction, please top up your company’s main
              balance.
            </TypeFezNeueLight>
          </div>
        }
        middle={
          <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
            <CenterendIconLocalTransfer icon={ModalIcon} />
          </MiddleWrapper>
        }
        body={
          <div>
            <div
              style={{
                marginTop: 24,
                marginBottom: 16,
              }}
            >
              <LeftRightList array={array} />
              <div
                style={{ borderBottom: "2px dotted #C2C2C2", marginBottom: 16 }}
              />
              <LeftRightList array={array2} />
            </div>
            <CustomButton
              className="w-100"
              onClick={() => {
                setOpenTopUpModal(true);
              }}
            >
              Top Up Now
            </CustomButton>
          </div>
        }
      />
      <TopUpModal
        modal={openTopUpModal}
        toggle={() => {
          setOpenTopUpModal(false);
        }}
        onClickPaid={() => handleClick()}
        paid
      />
    </>
  );
};

export const ModalTemplate = ({
  isOpen,
  headerStyle,
  header = null,
  middleStyle,
  middleHeight,
  middle = null,
  bodyStyle,
  body = null,
  paddingTop = 24,
  hideModal,
  toggle: toggleProps,
  wingComponent,
  isWhite = false,
  loading,
}) => {
  const {
    figmaHeight,
    height,
    width,
    isTabOrPhone,
    isSmall,
    sidebarTotalWidth,
    is1440,
    mainComponentWidth,
  } = windowDimension();

  const toggle = () => {
    if (toggleProps) return toggleProps();
    return header?.props?.toggle && header?.props?.toggle();
  };

  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      className="my-0"
      centered
      contentClassName="modal-transfez"
    >
      {body}
    </Modal>
  );
};

export const UploadInvoiceModal = ({
  isOpen,
  toggle,
  uploadQuota = 10,
  onUpload,
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const useFormObj = useForm();
  const { handleSubmit, watch, reset, unregister } = useFormObj;
  const watchFile = watch("upload_file");
  const isDisabled = Boolean(!watchFile?.length);
  const maxFilesReached = Boolean(watchFile?.length > uploadQuota);

  const {
    isSeedPlan,
    invoice_qty_left,
    max_invoice_free_fee,
    loadingSubscriptionUsage,
  } = useSubscriptionUsage();
  const invoiceQtyLeft = (invoice_qty_left ?? 0) - (10 - uploadQuota);
  const isV2SeedPlan = invoice_qty_left != null && isSeedPlan;
  const isOverV2SeedLimit =
    isV2SeedPlan && (watchFile?.length ?? 0) > (invoiceQtyLeft ?? 0);
  const isReachedV2SeedLimit =
    isV2SeedPlan && (watchFile?.length ?? 0) >= (invoiceQtyLeft ?? 0);
  const companyLimit = max_invoice_free_fee ?? 0;
  const usedCompanyLimit =
    (watchFile?.length ?? 0) +
    ((max_invoice_free_fee ?? 0) - (invoiceQtyLeft ?? 0));

  const onSubmit = async ({ upload_file }) => {
    setSubmitLoading(true);
    await onUpload(upload_file);
    setSubmitLoading(false);
    handleClose();
  };

  const handleClose = () => {
    unregister("upload_file");
    toggle();
  };

  const marginBottomDecider = () => {
    if (isOverV2SeedLimit) return 90;
    if (isReachedV2SeedLimit) return 90;
    return 70;
  };
  const containerMaxHeightDecider = () => {
    if (isOverV2SeedLimit) return 140;
    if (isReachedV2SeedLimit) return 140;
    if (maxFilesReached) return 140;
    return 174;
  };

  const marginBottom = marginBottomDecider();
  const containerMaxHeight = containerMaxHeightDecider();

  if (submitLoading) return null;
  return (
    <ModalTemplate
      isOpen={isOpen}
      body={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: 550,
            minHeight: 550,
            height: "auto",
            backgroundColor: "#FFFFFF",
            borderRadius: 12,
            padding: 24,
          }}
        >
          <CloseButton
            style={{ top: 8, right: 8, position: "absolute" }}
            onClick={handleClose}
          />
          <GothamMedium
            style={{ fontSize: 24, color: "#000000", marginBottom: 32 }}
          >
            Upload Invoice
          </GothamMedium>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <GothamMedium style={{ marginBottom: 8 }}>
              You can upload up to {uploadQuota}{" "}
              {pluralize(uploadQuota, "file")}
            </GothamMedium>
            {isV2SeedPlan && (
              <CompanyLimit used={usedCompanyLimit} limit={companyLimit} />
            )}
          </div>
          <div style={{ marginBottom }}>
            <FileInputJack
              label="You can submit up to 10 files"
              fileTypes={["image/jpeg", "application/pdf", "image/png"]}
              useFormObj={useFormObj}
              name="upload_file"
              multiple={true}
              maxFiles={uploadQuota}
              containerMaxHeight={containerMaxHeight}
              customError={maxFilesReached && <InvoiceErrorText />}
              showLabel={false}
              firstCopy="Browse or drag files here"
              middleComponent={isReachedV2SeedLimit && <UpgradeLimitBanner />}
            />
          </div>
          <div
            style={{
              borderTop: `1px solid ${jackColors.neutral500}`,
              position: "absolute",
              bottom: 24,
              width: "100%",
              left: 0,
              paddingLeft: "24px",
              paddingTop: "20px",
              backgroundColor: "white",
            }}
          >
            {isOverV2SeedLimit && (
              <OverLimit
                over={(watchFile?.length ?? 0) - (invoiceQtyLeft ?? 0)}
              />
            )}
            <ButtonJack
              style={{
                width: 502,
                marginTop: "8px",
              }}
              onClick={handleSubmit(onSubmit)}
              isLoading={submitLoading}
              disabled={
                isDisabled ||
                maxFilesReached ||
                isOverV2SeedLimit ||
                loadingSubscriptionUsage
              }
            >
              Continue
            </ButtonJack>
          </div>
        </div>
      }
    />
  );
};

export const UploadGuideLineModal = ({ isOpen, toggle }) => {
  return (
    <ModalTemplate
      isOpen={isOpen}
      toggle={toggle}
      body={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: 628,
            height: 626,
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
            borderRadius: 20,
            padding: "24px 24px",
            gap: 24,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <CloseHeader color="#333334" isMedium isJustClose toggle={toggle} />
            <TypeFezNeueBold style={{ fontSize: 24, color: "#102C57" }}>
              Upload Guideline
            </TypeFezNeueBold>
            <TypeFezNeueLight style={{ color: "#6C6C71" }}>
              Invoice document
            </TypeFezNeueLight>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: 42,
              backgroundColor: "#E1EEFF",
              borderRadius: 8,
              padding: "12px 12px",
            }}
          >
            <div className="d-flex">
              <img src={lightBulbIcon} style={{ color: "#102C57" }} />
              <TypeFezNeueMedium>Tips</TypeFezNeueMedium>
            </div>
            <div
              style={{ width: 502 }}
              className="d-flex justify-content-center"
            >
              <TypeFezNeueLight style={{ fontSize: 12 }}>
                Follow this guide to ensure a smooth invoice payment process.
              </TypeFezNeueLight>
            </div>
          </div>
          <div style={{ height: 400 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  width: 328,
                  height: 400,
                  backgroundColor: "#EAEAEA",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={dummyPdf} />
              </div>
              <div
                style={{
                  width: 240,
                  display: "flex",
                  flexDirection: "column",
                  gap: 12,
                }}
              >
                <div>
                  <div className="d-flex">
                    <TypeFezNeueLight style={{ fontSize: 14, marginRight: 5 }}>
                      1.
                    </TypeFezNeueLight>
                    <TypeFezNeueLight style={{ fontSize: 14 }}>
                      You can submit documents in{" "}
                      <TextInlineBold>PDF</TextInlineBold>,{" "}
                      <TextInlineBold>JPEG</TextInlineBold>, or{" "}
                      <TextInlineBold>PNG</TextInlineBold> format
                    </TypeFezNeueLight>
                  </div>
                </div>
                <div className="d-flex">
                  <TypeFezNeueLight style={{ fontSize: 14, marginRight: 5 }}>
                    2.
                  </TypeFezNeueLight>
                  <TypeFezNeueLight style={{ fontSize: 14 }}>
                    You may submit in in{" "}
                    <TextInlineBold>
                      photos, hard copies that have been scanned,
                    </TextInlineBold>
                    , or{" "}
                    <TextInlineBold>documents in digital form</TextInlineBold>
                  </TypeFezNeueLight>
                </div>
                <div className="d-flex">
                  <TypeFezNeueLight style={{ fontSize: 14, marginRight: 5 }}>
                    3.
                  </TypeFezNeueLight>
                  <TypeFezNeueLight style={{ fontSize: 14 }}>
                    Ensure the text is <TextInlineBold>readable</TextInlineBold>
                  </TypeFezNeueLight>
                </div>
                <div className="d-flex">
                  <TypeFezNeueLight style={{ fontSize: 14, marginRight: 5 }}>
                    4.
                  </TypeFezNeueLight>
                  <TypeFezNeueLight style={{ fontSize: 14 }}>
                    Document should not be{" "}
                    <TextInlineBold>folded</TextInlineBold>, or{" "}
                    <TextInlineBold>damaged</TextInlineBold>
                  </TypeFezNeueLight>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export const InvoiceErrorText = () => (
  <>
    <TextInlineMedium>You’ve reached max 10 invoices.</TextInlineMedium> Please
    remove any extra file(s) to continue.
  </>
);
