import { isEmpty } from "lodash";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { fetch } from "../../../tools/api";
import { useGetAuth } from "../../../contexts/AuthContext";

export const useSubscriptionUsage = () => {
  const { user } = useGetAuth();

  const { data, loading, refetch } = fetch({
    url: "/subscription_module/business_usage",
    formatter: (res) => res.data,
    defaultValue: {},
    woInit: true,
  });

  const {
    account_status,
    max_physical_cards,
    max_users,
    max_virtual_cards,
    plan_type,
    total_physical_cards,
    total_users,
    total_virtual_cards,
    due_date,
    invoice_qty_left,
    reimbursement_qty_left,
    max_invoice_free_fee,
    max_reimbursement_free_fee,
  } = data || {};

  const isSuspended = account_status === "suspended";
  const isOverdue = account_status === "overdue";

  const isSeedPlan = plan_type === "seed";
  const isGrowthPlan = ["long term", "growth"].includes(plan_type);
  const isGrowthPlanStrict = plan_type === "growth";
  const isLongTermPlanStrict = plan_type === "long term";
  const isUltimate = plan_type === "ultimate";

  const isUnlimitedPhysicalCards = max_physical_cards < 0;
  const isUnlimitedVirtualCards = max_virtual_cards < 0;
  const isUnlimitedUsers = max_users < 0;

  const isReachedMaxPhysicalCards =
    total_physical_cards >= max_physical_cards && !isUnlimitedPhysicalCards;
  const isReachedMaxVirtualCards =
    total_virtual_cards >= max_virtual_cards && !isUnlimitedVirtualCards;
  const isReachedMaxCards =
    isReachedMaxPhysicalCards && isReachedMaxVirtualCards;

  const isReachedMaxSeats = total_users >= max_users && !isUnlimitedUsers;

  const isSeedV2Invoice = isSeedPlan && invoice_qty_left != null;
  const isReachedInvoiceLimit = isSeedV2Invoice && invoice_qty_left <= 0;

  const isSeedV2Reimbursement = isSeedPlan && reimbursement_qty_left != null;
  const isReachedReimbursementLimit =
    isSeedV2Reimbursement && reimbursement_qty_left <= 0;

  const dueDate = isGrowthPlan ? moment(due_date).format("LL") : "";

  const thereIsNoUsage = isEmpty(data);

  useEffect(() => {
    if (!isEmpty(user)) refetch();
  }, [!isEmpty(user)]);

  return {
    account_status,
    max_physical_cards,
    max_users,
    max_virtual_cards,
    plan_type,
    total_physical_cards,
    total_users,
    total_virtual_cards,
    dueDate,
    isSuspended,
    isOverdue,
    isGrowthPlan,
    isGrowthPlanStrict,
    isLongTermPlanStrict,
    thereIsNoUsage,
    loadingSubscriptionUsage: loading,
    isSeedPlan,
    isReachedMaxCards,
    isReachedMaxPhysicalCards,
    isReachedMaxVirtualCards,
    invoice_qty_left,
    reimbursement_qty_left,
    refetch,
    isReachedMaxSeats,
    max_invoice_free_fee,
    max_reimbursement_free_fee,
    isReachedInvoiceLimit,
    isReachedReimbursementLimit,
    isSeedV2Invoice,
    isSeedV2Reimbursement,
    isUltimate,
  };
};

export const useSubscriptionBillings = () => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const { user } = useGetAuth();

  const { data, loading, refetch } = fetch({
    url: "/subscription_module/bills",
    params: { page },
    woInit: true,
    formatter: (res, prev) => {
      if (page === 1) return res;
      return { ...prev, data: [...prev.data, ...res.data] };
    },
    afterSuccess: (res) => setTotalPage(res.total_page),
  });

  useEffect(() => {
    if (isEmpty(user)) return;
    if (page <= totalPage) refetch();
  }, [page, totalPage, isEmpty(user)]);

  const billings = data.data ?? [];
  const dueDate = data.due_date ?? billings[0]?.due_date ?? new Date();
  const billingEmail = data.bill_email ?? "";
  const costBreakdown = data.cost_breakdown ?? {};
  const totalFreeFeeLocalTransfer =
    data?.total_free_qty?.total_free_local_transfer_quantity ?? 0;

  return {
    dueDate,
    billings,
    billingEmail,
    costBreakdown,
    loading,
    page,
    totalPage,
    totalFreeFeeLocalTransfer,
    setPage,
    cleanRefetch: async () => {
      if (isEmpty(user)) return;
      if (page === 1) return refetch();
      setPage(1);
    },
  };
};

export const useSubscriptionTemplates = () => {
  const { user } = useGetAuth();

  const { data, loading, refetch } = fetch({
    url: "/subscription_module/templates",
    defaultValue: {},
    woInit: true,
  });

  const rules = (planType = "seed") => {
    const templates = data?.data;
    if (!templates) return {};
    return (
      (templates || []).find(({ plan_type }) => plan_type === planType) ?? {}
    );
  };

  const seedPlanRules = rules("seed");
  const growthPlanRules = rules("growth");
  const customPlanRules = rules("long term");

  useEffect(() => {
    if (!isEmpty(user)) refetch();
  }, [!isEmpty(user)]);

  return { loading, seedPlanRules, growthPlanRules, customPlanRules };
};
