import { isEmpty } from "lodash";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { fetch } from "../api";

const useSubscriptionUsage = () => {
  const [showSuspendOrOverdueModal, setShowSuspendOrOverdueModal] =
    useState(false);

  const { data, loading, refetch } = fetch({
    url: "/subscription_module/business_usage",
    formatter: (res) => res.data,
    defaultValue: {},
  });

  const {
    account_status,
    max_physical_cards,
    max_users,
    max_virtual_cards,
    plan_type,
    total_physical_cards,
    total_users,
    total_virtual_cards,
    due_date,
  } = data || {};

  const isSuspended = account_status === "suspended";
  const isOverdue = account_status === "overdue";

  const isSeedPlan = plan_type === "seed";
  const isGrowthPlan = ["long term", "growth"].includes(plan_type);
  const isGrowthPlanStrict = plan_type === "growth";
  const isLongTermPlanStrict = plan_type === "long term";
  const isUltimatePlan = plan_type === "ultimate";
  const isUltimate = plan_type == "ultimate";

  const reachedMaxPhysicalCards =
    total_physical_cards >= max_physical_cards && !(max_physical_cards < 0);
  const reachedMaxVirtualCards =
    total_virtual_cards >= max_virtual_cards && !(max_virtual_cards < 0);
  const reachedMaxCards = reachedMaxPhysicalCards && reachedMaxVirtualCards;

  const dueDate = isGrowthPlan ? moment(due_date).format("LL") : "";

  const thereIsNoUsage = isEmpty(data);

  // To show modal if account status is suspended or overdue
  useEffect(() => {
    if ((isSuspended || isOverdue) && !showSuspendOrOverdueModal) {
      const showed = JSON.parse(
        localStorage.getItem("showed_suspend_or_overdue_modal")
      );

      if (!showed) {
        setShowSuspendOrOverdueModal(true);
      }
    }
  }, [isSuspended, isOverdue, showSuspendOrOverdueModal]);

  // To set localStorage if the modal has already been showed in the user login session
  // The user will not see the modal twice or more
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (showSuspendOrOverdueModal) {
        localStorage.setItem("showed_suspend_or_overdue_modal", true);
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, [showSuspendOrOverdueModal]);

  return {
    refetch,
    account_status,
    max_physical_cards,
    max_users,
    max_virtual_cards,
    plan_type,
    total_physical_cards,
    total_users,
    total_virtual_cards,
    dueDate,
    isSuspended,
    isOverdue,
    isGrowthPlan,
    isGrowthPlanStrict,
    isLongTermPlanStrict,
    isUltimate,
    thereIsNoUsage,
    loadingSubscriptionUsage: loading,
    showSuspendOrOverdueModal,
    isSeedPlan,
    reachedMaxCards,
    reachedMaxPhysicalCards,
    reachedMaxVirtualCards,
    isUltimatePlan,
  };
};

export default useSubscriptionUsage;
