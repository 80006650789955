import { NakedSearchInput, SearchInput } from "../../../../components/inputs";
import { useModalHook } from "../../../../components/Modals";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import plusIcon from "../../../../assets/images/plus-grey-icon.svg";
import plusIconPink from "../../../../assets/images/plus-pink-icon.svg";
import deleteGreyIcon from "../../../../assets/images/delete-grey-icon.svg";
import deletePinkIcon from "../../../../assets/images/delete-pink-icon.svg";
import deleteWhiteIcon from "../../../../assets/images/delete-white-icon.svg";
import { GothamMedium } from "../../../../components/Text";
import { ModalDelete } from "../../../PayrollCreateEnhancement/create/modal";
import { colors, jackColors } from "../../../../assets/colors";
import { AddRecipientFromList, ModalAddRowLocal } from "./modal";
import { eventsTracker } from "../../../../universalFunctions/events";
import { NakedTransactionFiltersSearchbox } from "../../../../pageComponents/transactionsComponents/transactions/filters/searchbox/component";
import { TransactionFiltersSearchbox } from "../../../transactionsComponents/transactions/filters/searchbox/parent";
import { useRef } from "react";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { dummyDataLocal } from "../second";
import {
  AddRowExistingListFormatter,
  DeleteStringDecider,
  checkerEmptyWhenUpload,
  removeAllEmptyField,
} from "./logic";
import { useRouter } from "next/router";

export const ToggleLayout = ({
  title = "Add New Recipient",
  onClick,
  icon,
  active,
  style,
  fontStyle,
}) => {
  return (
    <div
      className="d-flex align-items-center hover-pink py-1 px-2"
      style={{
        cursor: "pointer",
        borderRadius: 100,
        backgroundColor: active && colors.lightPink,
        ...style,
      }}
      onClick={onClick}
    >
      <img src={icon} style={{ width: 20 }} className="mr-1" />
      <GothamMedium
        style={{ color: active ? colors.pink : colors.grey6c, ...fontStyle }}
      >
        {title}
      </GothamMedium>
    </div>
  );
};

export const SearchBarAndButton = ({
  data,
  search,
  setData,
  onChange,
  setAfterAddRow,
  headerArray,
  showDeleteRow,
  funcPropsTable,
  setShowDeleteRow,
  selectedIdsDelete,
  isScreenDuplicate,
  isScreenIncomplete,
  formattedOptionsBank,
}) => {
  const { query } = useRouter();
  const isResubmit = query.action === "resubmit";
  const { handleUncheckedAll, isAllChecked } = funcPropsTable || {};
  const showButtonAdd =
    !isScreenDuplicate && !isScreenIncomplete && !isResubmit;

  const refDiv = useRef();
  const widthParent = refDiv?.current?.clientWidth || 1200;
  const { isOpen, toggle, close } = useModalHook();
  const {
    isOpen: isOpenAdd,
    toggle: toggleAdd,
    close: closeAdd,
  } = useModalHook();
  const {
    isOpen: isOpenList,
    toggle: toggleList,
    close: closeList,
  } = useModalHook();

  const { successSnackBar, errorSnackBar } = ToasterHook();

  const deleteCounter = selectedIdsDelete.length;
  const isSelectAll = data.length == deleteCounter;
  const descModal = isSelectAll
    ? `You will delete all transactions and have to start 
    all over again.`
    : `You will delete ${deleteCounter} selected transactions.`;

  const handleOpenModalDelete = () => {
    if (isAllChecked)
      return errorSnackBar({
        msg: "You can't delete all of your transaction(s)",
        showClose: true,
      });
    if (deleteCounter > 0) {
      eventsTracker("local_transfer_confirmation_modal_delete_recipient");
      toggle();
    }
  };

  const handleOpenModalAdd = () => {
    eventsTracker("local_transfer_open_modal_add_recipient");
    setData([...data, dummyDataLocal]);
    // toggleAdd();
    setAfterAddRow(true);
  };

  const handleDeleteSelected = () => {
    const filteredData = data.filter(
      (_, index) => selectedIdsDelete.indexOf(index) === -1
    );
    handleUncheckedAll();
    setShowDeleteRow(false);
    successSnackBar({
      msg: DeleteStringDecider(deleteCounter),
      showClose: true,
    });
    setData(filteredData);
    eventsTracker("local_transfer_submit_delete_recipient");
    close();
  };

  const handleAddRecipient = (val) => {
    const dataFormatted = AddRowExistingListFormatter({
      data: val,
      formattedOptionsBank,
      defaultData: data,
      dummyData: dummyDataLocal,
    });
    setData(dataFormatted);
    setAfterAddRow(true);
  };

  const setCombineData = (value) => {
    setAfterAddRow(true);
    eventsTracker("local_transfer_submit_add_recipient", value);
    setData([...data, ...value]);
  };

  return (
    <div ref={refDiv}>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ margin: "16px 0px 24px 0px" }}
      >
        <NakedTransactionFiltersSearchbox
          placeholder="Search keywords"
          onChange={onChange}
          value={search}
          labelStyle={{ height: 0 }}
        />
        {showButtonAdd && (
          <div className="d-flex" style={{ gap: 12 }}>
            <ButtonJack
              type="outline"
              onClick={handleOpenModalAdd}
              children="Add Empty Row"
              leftIcon={
                <JackIcons
                  name="plus-square-outline"
                  fill={jackColors.neutral900}
                  style={{ width: 20, height: 20 }}
                />
              }
              style={{ height: 32, padding: 6 }}
            />
            <ButtonJack
              type="outline"
              onClick={toggleList}
              children="Add From Recipient List"
              leftIcon={
                <JackIcons
                  style={{ width: 20, height: 20 }}
                  name="person-add-outline"
                  fill={jackColors.neutral900}
                />
              }
              style={{ height: 32, padding: 6 }}
            />
          </div>
        )}
      </div>
      {showDeleteRow && (
        <div className="mb-3 d-flex align-items-center">
          <GothamMedium>
            {selectedIdsDelete.length} transactions selected
          </GothamMedium>
          <ToggleLayout
            title="Delete Selected"
            icon={deleteWhiteIcon}
            style={{ backgroundColor: colors.pink, marginLeft: 8 }}
            fontStyle={{ color: "white" }}
            onClick={handleOpenModalDelete}
          />
          <ModalDelete
            isOpen={isOpen}
            toggleDelete={toggle}
            confirmDelete={handleDeleteSelected}
            desc={descModal}
          />
        </div>
      )}
      <AddRecipientFromList
        isOpen={isOpenList}
        toggle={toggleList}
        close={closeList}
        handleAddRecipient={handleAddRecipient}
      />
      <ModalAddRowLocal
        widthParent={widthParent}
        isOpen={isOpenAdd}
        toggle={toggleAdd}
        headerArray={headerArray}
        setCombineData={setCombineData}
      />
    </div>
  );
};
