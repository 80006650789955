import { isEmpty, pickBy } from "lodash";
import { useRouter } from "next/router";
import { useState } from "react";

export const useRunQueryStringNames = (stringNames = []) => {
  const { query, push } = useRouter();

  let objQueries = {};

  stringNames.forEach((name) => {
    const value = query[name];
    objQueries[name] = value;
  });

  const runQuery = () => {
    push({ query: { ...query, runQuery: false } });
    push({ query: { ...query, runQuery: true } });
  };

  const [prevValues, setPrevValues] = useState({});

  const reset = () => {
    setPrevValues({});
    let obj = query;
    stringNames.forEach((key) => delete obj[key]);
    push({ query: obj });
    runQuery();
  };

  const isNotChanged = Object.keys(objQueries).every((key) => {
    const queryValue = objQueries[key];
    const prevValue = prevValues[key];

    return queryValue == prevValue;
  });
  const listener = (isOpen) => {
    if (isOpen) return;

    setPrevValues(objQueries);
    if (isNotChanged) return;
    runQuery();
  };

  return {
    reset: !isEmpty(pickBy(objQueries, (value) => value)) && reset,
    listener,
  };
};
