import { format, parseISO } from "date-fns";
import { jackColors } from "../../assets/colors";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { forEach, isArray } from "lodash";
import { rowDuplicateFilter } from "../draftComponents/tools/helpers";
import { ToasterHook } from "../../contexts/ToasterContext";
import { useModalHook } from "../../components/Modals";
import { LedgersOnboardingModal } from "./common/ledgersOnboardingModal";
import { icon24 } from "../virtualAccountComponents/common/utils";
import { JackIcons } from "../../assets/jackIcons/parent";
import {
  apiBusiness,
  fetch,
  handleDownloadRaw,
  useMutation,
} from "../../tools/api";
import { Avatar } from "../../components/Avatar";
import CrossBorderIcon from "../../assets/images/crossborder-single-avatar.svg";
import { eventsTracker } from "../../universalFunctions/events";
import { usePushQuery } from "@tools";

export const themeBorder = { border: `1px solid ${jackColors.neutral500}` };

export const simpleDateFormatter = (date, dateFormat = "dd/MM/yyyy") => {
  if (!date) return null;
  const newDate = new Date(date);
  const formattedDate = format(newDate, dateFormat);
  return formattedDate;
  // => dd/mm/yyyy
};

export const useIntervalCustom = ({
  triggerFunction = () => {},
  triggerFunctionAfterTimeout = () => {},
  triggerIntervalDelay = 1000,
  timeoutTime = 10000,
  dependencies = [],
  ifStatement = false,
  willTrigger = false,
}) => {
  useEffect(() => {
    if (!willTrigger) return;
    let refetchInterval;

    const clearAndRefetch = () => {
      triggerFunction();
    };

    if (ifStatement) {
      refetchInterval = setInterval(clearAndRefetch, triggerIntervalDelay);
    }

    const timeoutId = setTimeout(() => {
      clearInterval(refetchInterval);
      triggerFunctionAfterTimeout();
    }, timeoutTime);

    return () => {
      if (refetchInterval) {
        clearInterval(refetchInterval);
      }
      clearTimeout(timeoutId);
    };
  }, dependencies);
};

export const universalCopyFunction = ({
  copyText = "",
  withSuccessMsg = () => {},
}) => {
  navigator.clipboard.writeText(copyText);
  withSuccessMsg();
  return;
};

export const LedgersModal = ({ menu = "va-pocket" }) => {
  const { isOpen: isOpenOnboardingModal, toggle: toggleOnboardingModal } =
    useModalHook();

  return (
    <>
      <JackIcons
        name="help-outline"
        fill={jackColors.neutral800}
        style={{ ...icon24, cursor: "pointer" }}
        onClick={toggleOnboardingModal}
      />
      <LedgersOnboardingModal
        isOpen={isOpenOnboardingModal}
        toggle={toggleOnboardingModal}
        menu={menu}
      />
    </>
  );
};

export const useFetchLedgers = ({
  menu = "va-pocket",
  dateObj = {},
  resultFormatter = () => {},
}) => {
  const isVaPocket = menu === "va-pocket";
  const isPrimaryBankAccount = menu === "primary";

  const PER_PAGE = 15;
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isMaxedOut, setIsMaxedOut] = useState(false);
  const [payload, setPayload] = useState({});

  const currentPage = `&page=${page}`;

  const { query, push } = useRouter();
  const changeDateQuery = query?.changeDate;
  const {
    searchbox,
    category,
    type,
    runQuery,
    sub_from_date,
    sub_to_date,
    filtered,
    resynced,
  } = query;

  const { gteq, lteq } = dateObj || {};

  const urlDecider = () => {
    if (isVaPocket)
      return `/va_pocket_balance?per_page=${PER_PAGE}&date_to=${
        sub_to_date || lteq
      }&date_from=${sub_from_date || gteq}`;
    if (isPrimaryBankAccount)
      return `/primary_balance?per_page=${PER_PAGE}&date_to=${
        sub_to_date || lteq
      }&date_from=${sub_from_date || gteq}`;
  };

  const urlBalanceDecider = () => {
    if (isVaPocket)
      return `/va_pocket_balance_credit_debit?date_to=${
        sub_to_date || lteq
      }&date_from=${sub_from_date || gteq}`;
    if (isPrimaryBankAccount)
      return `/primary_balance_credit_debit?date_to=${
        sub_to_date || lteq
      }&date_from=${sub_from_date || gteq}`;
  };

  // const filterQuery = queryHandler();
  // const url = `${urlDecider()}` + currentPage;
  const [url, setUrl] = useState(`${urlDecider()}` + currentPage);

  const { mutation, loading } = useMutation({
    method: "post",
    url,
    resultFormatter,
    afterSuccess: (res) => {
      if (res.data.data.length < PER_PAGE - 7) {
        setIsMaxedOut(true);
      }
      setData((prev) => {
        const isPrevArray = isArray(prev);
        const is422 = res.data.status === 422;
        if (is422) return [];
        let newArray;
        if (page == 1) {
          newArray = res.data.data;
        } else {
          newArray = [...(isPrevArray ? prev : prev.array), ...res.data.data];
        }

        const uniqueArray = rowDuplicateFilter(newArray);
        return uniqueArray;
      });
    },
  });

  const payloadFormer = () => {
    const keywordPayload = searchbox ? { keyword: searchbox } : {};
    const categoryPayload = category
      ? {
          category_id: isArray(category)
            ? category.map((item) => +item)
            : [+category],
        }
      : {};
    const typePayload = type ? { type: isArray(type) ? type : [type] } : {};

    return {
      ...keywordPayload,
      ...categoryPayload,
      ...typePayload,
    };
  };

  const refetch = () => mutation(payload);

  const {
    data: dataBalance,
    loading: loadingBalance,
    refetch: refetchBalanceRaw,
    setData: setDataBalance,
  } = fetch({
    url: urlBalanceDecider(),
  });

  const nextPage = () => {
    if (isMaxedOut) return;
    if (loading) return;
    setPage((p) => p + 1);
  };

  const refetchBalance = () => {
    setDataBalance({});
    refetchBalanceRaw();
  };

  const formattedData = {
    array: data,
    ending_balance: dataBalance?.ending_balance, // change endpoint
    total_credit: dataBalance?.total_credit,
    total_debit: dataBalance?.total_debit,
    dataType: isVaPocket ? "va-pocket" : "primary",
  };

  const keywordPayload = payload?.keyword;
  const typePayload = payload?.type;
  const categoryPayload = payload?.category_id;

  const { pushQuery } = usePushQuery();

  useEffect(() => {
    if (filtered) return;
    refetch();
  }, [url]);

  useEffect(() => {
    if (!resynced) return;
    setData([]);
    setPage(1);
    setIsMaxedOut(false);
    refetch();
    pushQuery("resynced", false);
  }, [resynced]);

  useEffect(() => {
    if (runQuery === "false" || runQuery === undefined) return;
    // if (filtered === "false" || filtered === undefined) return;
    refetch();
    pushQuery("filtered", false);
  }, [keywordPayload, typePayload, categoryPayload]);

  useEffect(() => {
    if (filtered === "true") {
      refetch();
      pushQuery("filtered", false);
    }
  }, [filtered]);

  useEffect(() => {
    if (runQuery === "false" || runQuery == undefined) return;
    setData([]);
    setPage(1);
    setIsMaxedOut(false);
    setPayload(payloadFormer());
    setUrl(`${urlDecider()}` + currentPage);
    pushQuery("filtered", true);
  }, [runQuery]);

  useEffect(() => {
    if (!changeDateQuery || changeDateQuery == "false") return;
    refetchBalance();
  }, [changeDateQuery]);

  useEffect(() => {
    if (!changeDateQuery) return;
    setData([]);
    setIsMaxedOut(false);
    setPayload({});

    if (page !== 1) {
      setPage(1);
    }
    push({ query: { changeDate: false } });
  }, [menu, changeDateQuery]);

  useEffect(() => {
    if (isMaxedOut) return;
    setUrl(`${urlDecider()}` + currentPage);
  }, [page, changeDateQuery]);

  return {
    data: formattedData,
    loading,
    nextPage,
    refetch,
    setData,
    dataBalance,
    loadingBalance,
    refetchBalance,
  };
};

export const useResyncLedger = () => {
  const { pushQuery } = usePushQuery();
  const { mutation: mutationResync, loadingResync } = useMutation({
    method: "post",
    url: "/sync_statements",
    afterSuccess: (res) => {
      pushQuery("resynced", true);
    },
  });

  return { mutationResync, loadingResync };
};

const nameDecider = (originator_type = "", source = "") => {
  const type = originator_type?.toLowerCase();
  const isReimbursement = type === "reimbursement";
  const isWithdraw = type === "user";
  const isLocalDisbursements =
    type === "transaction" ||
    type === "batch" ||
    type === "cashinadvancetransaction" ||
    type === "localtransactionbatch";
  const isTopUp = type === "vatransaction";
  const isIncomingPayment = type === "vatransaction";
  const isInvoice = type === "invoicetransaction";
  const isCardMoveBalance = type === "journalentry";
  const isPayroll = type === "payroll";
  const isCollection = type === "collection";
  const isMoneyMovement = type === "businessinternaltransfer";
  const isCrossBorder = type === "businesstransaction";
  const isCard = type === "cardtransaction";
  const isCardBillPayment = type === "bill";

  if (!type) return "Money2";

  // const isCardMoveBalance = isCardMoveBalanceRaw && source == "main_balance";
  // const isCardCreditTopUp = isCardCreditTopUpRaw && source == "main_balance";
  if (isReimbursement) return "Reimbursement";
  if (isInvoice) return "Invoice Payment";
  if (isPayroll) return "Payroll";
  if (isCrossBorder) return CrossBorderIcon;
  if (isLocalDisbursements) return "Send Money";
  // if (isEWallet) return "wallet";
  if (isCard) return "Corporate card";
  if (isMoneyMovement) return "Money Movement";
  if (isTopUp || isIncomingPayment) return "top up";
  if (isWithdraw) return "withdraw";
  if (isCardMoveBalance) return "Money Movement";
  // if (isCardMoveBalanceRaw) return "Corporate card";
  if (isCardBillPayment) return "card payment";
  // if (isRefund) return "refund";
  if (isCollection) return "coin";

  // if (isCardTransaction) return "Corporate card";
  // if (isApiTransaction) return "API";
  // if (isAlalocate || isReAllocate) return "Money Movement";
  // if (isConversion || isConvert) return "Money Movement";
  // if (isJackTransfer) return "swap-outline";

  return originator_type;
};

export const ProductTypeIconJack = ({
  originator_type,
  source,
  size = "medium",
}) => {
  const name = nameDecider(originator_type, source);

  const widthDecider = (size) => {
    if (size == "normal") return { width: 32, iconWidth: 20 };
    if (size == "smallest")
      return { width: 20, iconWidth: 12, customCrossBorderSize: 20 };

    return { width: 32, iconWidth: 20 };
  };

  const isExternal = originator_type === "BusinessTransaction";

  const { width, iconWidth, customCrossBorderSize } = widthDecider(size);

  if (isExternal) {
    return (
      <div style={{ width: width }}>
        <img src={name} style={{ width: customCrossBorderSize }} />
      </div>
    );
  }

  return (
    <div style={{ width: width }}>
      <Avatar
        size={size}
        mainIcon={
          <JackIcons
            name={name}
            fill="white"
            style={{ width: iconWidth, height: iconWidth }}
          />
        }
      />
    </div>
  );
};

export const handleOpenFileNewTab = ({
  data,
  type = "application/pdf",
  fileName = "",
}) => {
  const file = new Blob([data], {
    type,
  });
  const targetUrl = window.URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = targetUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  window.open(link, "_blank");
  // link.click();
  link.parentNode && link.parentNode.removeChild(link);
};

export const useUniversalDownload = ({
  url = "",
  payload = {},
  fromDate = new Date(),
  toDate = new Date(),
  isSource = true,
  openNewTab = false,
  fileName = "Jack-Activity-History",
  dependencies = [],
  additionalStatement = false,
  isGet = false,
  noChecking = false,
}) => {
  const [isExceeded, setIsExceeded] = useState(false);
  const [loading, setLoading] = useState(false);

  const { successToaster, errorToaster, errorSnackBar } = ToasterHook();

  const effectDependenciesArray = isArray(dependencies)
    ? dependencies
    : [dependencies];

  useEffect(async () => {
    if (additionalStatement) return;
    if (!fromDate) return;
    if (!toDate) return;
    if (isGet) return;
    if (noChecking) return;
    try {
      setLoading(true);
      const { data } = await apiBusiness.post(url, payload);
      const { status, missing_categories } = data || {};
      const is202 = status == 202;
      setIsExceeded(is202);
    } catch (error) {
      console.log("error:", error);
    } finally {
      setLoading(false);
    }
  }, effectDependenciesArray);

  const onSubmit = async (toggle, format) => {
    // const url = urlDecider(format);
    const fileType = format === "xlsx" ? "excel" : "pdf";
    const mimeType =
      format === "xlsx"
        ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        : "application/pdf";

    try {
      setLoading(true);
      const formattedPayload = {
        ...payload,
        force_download: true,
        file_type: fileType,
      };

      let res;
      if (isGet) {
        res = await apiBusiness.get(url);
      } else {
        res = await apiBusiness.post(url, formattedPayload, {
          responseType: "blob",
        });
      }

      // const res = await apiBusiness.post(url, formattedPayload, {
      //   responseType: "blob",
      // });

      const { data } = res;

      const createFileName = (string) =>
        createFileNameFromToXls({ string, from: fromDate, to: toDate, format });

      // const isSentToEmail = data?.type == "application/json";

      const eventObj = {
        from: fromDate,
        to: toDate,
      };

      const homeOrActivity = isSource ? "activity" : "home";

      if (isExceeded) {
        successToaster({
          title: "Export in progress",
          msg: "You will receive an email shortly.",
        });
        toggle();
        eventsTracker(`download_report_to_email`, eventObj);
        return setLoading(false);
      }

      const popupBlockerChecker = () => {
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        document.body.appendChild(iframe);

        try {
          const newWin = iframe.contentWindow.open("");
          if (
            !newWin ||
            newWin.closed ||
            typeof newWin.closed === "undefined"
          ) {
            document.body.removeChild(iframe);
            return true; // Popup blocker detected
          }
          newWin.close(); // Close the window immediately
        } catch (error) {
          // If an error occurs, it's likely due to a popup blocker
          document.body.removeChild(iframe);
          return true; // Popup blocker detected
        }

        document.body.removeChild(iframe);
        return false; // Popup blocker not detected
      };

      const isPopupBlockerOn = popupBlockerChecker();

      if (openNewTab && !isPopupBlockerOn) {
        handleOpenFileNewTab({
          data,
          type: mimeType,
          fileName: isSource ? fileName : createFileName(fileName),
        });
      } else {
        handleDownloadRaw({
          data,
          type: mimeType,
          fileName: isSource ? fileName : createFileName(fileName),
        });
      }

      successToaster({
        title: "Export in progress",
        msg: "We’re exporting your file. The download process will start shortly.",
      });

      eventsTracker(`download_report_activity_${homeOrActivity}`, eventObj);

      if (toggle) {
        toggle();
      }
    } catch (error) {
      const { response } = error;
      const { status } = response || {};
      const is422 = status == 422;

      if (!is422)
        return errorSnackBar({ msg: "No transactions in the selected range" });

      const { message } = JSON.parse(await response?.data.text());

      errorSnackBar({ msg: message });
    } finally {
      setLoading(false);
    }
  };

  return { onSubmit, loading };
};

export const pascalCaseToSpaced = (str) => {
  return str.replace(/([a-z])([A-Z])/g, "$1 $2");
};
