import React from "react";
import { NakedSearchInput } from "../../../components/inputs";
import plusIcon from "../../../assets/images/plus-grey-icon.svg";
import plusIconPink from "../../../assets/images/plus-pink-icon.svg";
import deleteGreyIcon from "../../../assets/images/delete-grey-icon.svg";
import deletePinkIcon from "../../../assets/images/delete-pink-icon.svg";
import deleteWhiteIcon from "../../../assets/images/delete-white-icon.svg";
import caretRightIcon from "../../../assets/images/caret-right-white-icon.svg";
import caretRightBgIcon from "../../../assets/images/caret-right-bg-white-icon.svg";
import caretLeftBgIcon from "../../../assets/images/caret-left-bg-white-icon.svg";
import warningIcon from "../../../assets/images/warning-red-icon.svg";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { colors, jackColors } from "../../../assets/colors";
import { useModalHook } from "../../../components/Modals";
import { dummyDataPayroll, ModalAddRow, ModalDelete } from "./modal";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { CustomButton, OutlineButton } from "../../../components/Buttons";
import { formatCurrencyNoDecimal } from "../../../components/tools";
import { isThereIncompleteData } from "./logic";
import { BackNavigation } from "../../accountDashboardComponents";
import { useRouter } from "next/router";
import { CenteredModal } from "../../../modals/centeredComponents";
import FeeConfirmation from "../../../components/SubscriptionComponents/FeeConfirmation/FeeConfirmation";
import FeeBreakdown from "../../../components/SubscriptionComponents/FeeBreakdown/FeeBreakdown";
import FeeCalculation from "../../../components/SubscriptionComponents/FeeCalculation/FeeCalculation";
import useSubscriptionUsage from "../../../tools/hooks/useSubscriptionUsage";
import useSubscriptionBillings from "../../../tools/hooks/useSubscriptionBilings";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { NakedTransactionFiltersSearchbox } from "../../transactionsComponents/transactions/filters/searchbox/component";
import { eventsTracker } from "../../../universalFunctions/events";
import { AddRecipientFromList } from "../../localTransferEnhancement/create/table/modal";
import { AddRowExistingListFormatter } from "../../localTransferEnhancement/create/table/logic";
import { AddPrevRecipientPayroll } from "./table/logic";

export const ToggleLayout = ({
  title = "Add Row",
  onClick,
  icon,
  active,
  style,
  fontStyle,
}) => {
  return (
    <div
      className="d-flex align-items-center hover-pink py-1 px-2"
      style={{
        cursor: "pointer",
        borderRadius: 100,
        backgroundColor: active && colors.lightPink,
        ...style,
      }}
      onClick={onClick}
    >
      <img src={icon} style={{ width: 20 }} className="mr-1" />
      <GothamMedium
        style={{ color: active ? colors.pink : colors.grey6c, ...fontStyle }}
      >
        {title}
      </GothamMedium>
    </div>
  );
};

export const SearchBarAndButton = ({
  data,
  search,
  setData,
  onChange,
  setAfterAdd,
  headerArray,
  isAllChecked,
  showDeleteRow,
  setShowDeleteRow,
  selectedIdsDelete,
  handleUncheckedAll,
  isScreenDuplicate,
  isScreenIncomplete,
  formattedOptionsBank,
}) => {
  const { isOpen, toggle, close } = useModalHook();

  const { query } = useRouter();
  const isResubmit = query.action === "resubmit";

  const showButtonAdd =
    !isScreenDuplicate && !isScreenIncomplete && !isResubmit;

  const {
    isOpen: isOpenAdd,
    toggle: toggleAdd,
    close: closeAdd,
  } = useModalHook();

  const {
    isOpen: isOpenList,
    toggle: toggleList,
    close: closeList,
  } = useModalHook();

  const { successToaster, errorToaster } = ToasterHook();
  const deleteCounter = selectedIdsDelete.length;
  const isSelectAll = data.length == deleteCounter;
  const descModal = isSelectAll
    ? `You will delete all transactions and have to start 
  all over again.`
    : `You will delete ${deleteCounter} selected transactions.`;

  const handleOpenModal = () => {
    if (isAllChecked) {
      return errorToaster(
        "Error!",
        "You can't delete all of your transaction(s)",
        5000
      );
    }
    if (deleteCounter > 0) {
      toggle();
    }
  };

  const handleOpenModalAdd = () => {
    eventsTracker("payroll_open_modal_add_recipient");
    setData([...data, dummyDataPayroll]);
    setAfterAdd(true);
  };

  const handleAddRecipient = (val) => {
    const dataFormatted = AddPrevRecipientPayroll({
      data: val,
      formattedOptionsBank,
      defaultData: data,
      dummyData: dummyDataPayroll,
    });
    setData(dataFormatted);
    setAfterAdd(true);
  };

  const handleDeleteSelected = () => {
    const filteredData = data.filter(
      (_, index) => selectedIdsDelete.indexOf(index) === -1
    );
    handleUncheckedAll();
    setShowDeleteRow(false);
    successToaster({
      title: "Deleted!",
      msg: `${deleteCounter} payroll transactions have been deleted.`,
    });
    setData(filteredData);
    close();
  };

  const setCombineData = (value) => {
    const newValue = value?.flat();
    setAfterAdd(true);
    setData([...data, ...value]);
  };

  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ margin: "16px 0px 24px 0px" }}
      >
        <NakedTransactionFiltersSearchbox
          placeholder="Search keywords"
          onChange={onChange}
          value={search}
          labelStyle={{ height: 0 }}
        />
        {showButtonAdd && (
          <div className="d-flex" style={{ gap: 12 }}>
            <ButtonJack
              type="outline"
              onClick={handleOpenModalAdd}
              children="Add Empty Row"
              style={{ backgroundColor: "white", height: 32, padding: 6 }}
              leftIcon={
                <JackIcons
                  name="plus-square-outline"
                  fill={jackColors.neutral900}
                  style={{ width: 20, height: 20 }}
                />
              }
            />
            <ButtonJack
              style={{ backgroundColor: "white", padding: 6, height: 32 }}
              type="outline"
              onClick={toggleList}
              children="Add From Employee List"
              leftIcon={
                <JackIcons
                  style={{ width: 20, height: 20 }}
                  name="person-add-outline"
                  fill={jackColors.neutral900}
                />
              }
            />
          </div>
        )}
        {/* {!isScreenIncomplete && (
          <>
            <ToggleLayout onClick={handleOpenModalAdd} icon={plusIcon} />
            <ToggleLayout
              onClick={setShowDeleteRow}
              active={showDeleteRow}
              title="Delete Row"
              icon={showDeleteRow ? deletePinkIcon : deleteGreyIcon}
            />
          </>
        )} */}
      </div>
      {/* {showDeleteRow && (
        <div className="mb-3 d-flex align-items-center">
          <GothamMedium>
            {selectedIdsDelete.length} transactions selected
          </GothamMedium>
          <ToggleLayout
            title="Delete Selected"
            icon={deleteWhiteIcon}
            style={{ backgroundColor: colors.pink, marginLeft: 8 }}
            fontStyle={{ color: "white" }}
            onClick={handleOpenModal}
          />
          <ModalDelete
            isOpen={isOpen}
            toggleDelete={toggle}
            confirmDelete={handleDeleteSelected}
            desc={descModal}
          />
        </div>
      )} */}
      <AddRecipientFromList
        isOpen={isOpenList}
        toggle={toggleList}
        close={closeList}
        handleAddRecipient={handleAddRecipient}
        isPayroll
      />
      <ModalAddRow
        isOpen={isOpenAdd}
        toggle={toggleAdd}
        headerArray={headerArray}
        setCombineData={setCombineData}
      />
    </div>
  );
};

export const ButtonAddRow = ({
  onClick = () => {},
  title = "Add Row",
  icon = plusIconPink,
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        border: "1px solid #E6E6E8",
        borderRadius: 100,
        gap: 4,
        width: 150,
        height: 32,
        padding: "8px",
        maxWidth: "auto",
        cursor: "pointer",
      }}
      className="d-flex justify-content-center align-items-center hover-pink"
    >
      <img src={icon} style={{ width: 16 }} />
      <GothamMedium style={{ color: colors.pink }}>{title}</GothamMedium>
    </div>
  );
};

export const BackAndConfirmButton = ({
  renderFirst = false,
  onClick,
  handleAddData,
}) => {
  const defaultStyle = {
    width: 134,
    height: 32,
    position: "unset",
  };

  return (
    <div className="d-flex justify-content-end" style={{ gap: 8 }}>
      <ButtonJack
        children="Cancel"
        type="outline"
        onClick={onClick}
        style={{ maxWidth: 66 }}
      />
      <ButtonJack
        children="Confirm"
        onClick={handleAddData}
        style={{ padding: "10px !important" }}
      />
      {/* {renderFirst ? (
        <CustomButton style={defaultStyle} onClick={onClick}>
          Next
        </CustomButton>
      ) : (
        <>
          <OutlineButton
            style={{ ...defaultStyle, border: "1px solid #e6e6e8" }}
            onClick={onClick}
          >
            Back
          </OutlineButton>
          <CustomButton style={defaultStyle} onClick={handleAddData}>
            Confirm
          </CustomButton>
        </>
      )} */}
    </div>
  );
};

export const FloatingButtonPayroll = ({
  data,
  array,
  isScreenIncomplete,
  selectedIds,
  isThereChecked,
  isFirstScreenTable,
  funcPropsTable,
  onClickOtherFields,
  isConfirmationPage,
  onClick = () => {},
  loading,
  showDeleteRow,
  hideNav,
}) => {
  const { pathname, query } = useRouter();
  const { selectedAmount, handleUncheckedAll, handleRemoveAllId } =
    funcPropsTable || {};

  const UncheckedAll = handleUncheckedAll || handleRemoveAllId;
  const { state } = query || {};

  const isLocalTransfer = pathname.includes("local-transfer");
  const isPayroll = pathname.includes("payroll");

  const isConfirmationPageLocal = state === "confirmation";

  const { isGrowthPlan, loadingSubscriptionUsage } = useSubscriptionUsage();
  const { totalFreeFeeLocalTransfer, loading: loadingSubscriptionBillings } =
    useSubscriptionBillings();

  const isLoadingSubscriptionItems =
    loadingSubscriptionUsage || loadingSubscriptionBillings;

  const isLoading = loading || isLoadingSubscriptionItems;

  const totalFreeTrxDecider = () => {
    if (isLocalTransfer)
      return totalFreeFeeLocalTransfer < 0 ? 0 : totalFreeFeeLocalTransfer;
    if (isPayroll) return Infinity;
  };

  const {
    totalTrx,
    totalAmount: totalAmountRaw,
    totalFee: totalFeeRaw,
  } = data || {};
  const totalAmount = formatCurrencyNoDecimal(totalAmountRaw);
  const totalFee = formatCurrencyNoDecimal(totalFeeRaw || 0);
  const individualFee = formatCurrencyNoDecimal(totalFeeRaw / totalTrx);
  const individualFeeUnformatted = totalFeeRaw / totalTrx;

  const totalFreeTrx = totalFreeTrxDecider();
  const totalCoveredTrx = totalFreeTrx - totalTrx;
  const someTrxAreNotCovered = totalCoveredTrx < 0;
  const totalUncoveredTrx = Math.abs(totalCoveredTrx);

  const amountPlusFeeDecider = () => {
    const totalAmount = Number(totalAmountRaw || 0);

    if (isGrowthPlan) {
      const feeDeductionRaw = Number(
        totalFeeRaw - individualFeeUnformatted * totalFreeTrx || 0
      );

      const feeDeduction = feeDeductionRaw <= 0 ? 0 : feeDeductionRaw;

      if (isLocalTransfer)
        return formatCurrencyNoDecimal(totalAmount + feeDeduction);

      if (isPayroll) return formatCurrencyNoDecimal(totalAmount + feeDeduction);
    }

    return formatCurrencyNoDecimal(totalAmount + Number(totalFeeRaw || 0));
  };

  const amountPlusFee = amountPlusFeeDecider();

  const isStillThereIncomplete = isThereIncompleteData({ data: array });
  const ImgIcon = isFirstScreenTable ? caretRightBgIcon : caretLeftBgIcon;
  const wordingButton = isConfirmationPage ? "Submit" : "Validate";

  const dataRendered = [
    {
      title: "Number of Transaction",
      desc: `${totalTrx} Transaction(s)`,
    },
    // ...(isConfirmationPage
    //   ? [
    //       {
    //         title: "Total Fee",
    //         desc: `IDR ${individualFee} x ${totalTrx} = IDR ${totalFee}`,
    //       },
    //     ]
    //   : []),
    {
      title: "Total Amount",
      desc: `IDR ${totalAmount}`,
    },
  ];

  const bottomDecider = () => {
    if (isScreenIncomplete) return 320;
    if (isConfirmationPage) return 0;
    if (showDeleteRow) return 240;
    return 190;
  };

  const bottomDeciderOtherFields = () => {
    if (isScreenIncomplete) return 400;
    if (isConfirmationPage) {
      if (isGrowthPlan) return 110;
      return 80;
    }
    if (showDeleteRow) return 320;
    return 270;
  };

  const feeCalculationProps = {
    totalFeeRaw,
    totalFreeTrx,
    individualFee,
    totalUncoveredTrx,
    someTrxAreNotCovered,
    individualFeeUnformatted,
  };

  return (
    <div>
      <div
        style={{
          position: "fixed",
          bottom: -10,
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 99,
          // margin: "10px 30px",
          width: 900,
          height: 80,
          backgroundColor: jackColors.neutral900,
          boxShadow:
            "0px 4px 12px -4px rgba(22, 34, 51, 0.12), 0px 16px 32px rgba(22, 34, 51, 0.16)",
          borderRadius: 8,
          padding: selectedAmount ? "20px 24px" : "16px 24px",
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          {selectedAmount ? (
            <GothamMedium
              className="font16"
              style={{ color: jackColors.neutral100 }}
            >
              {selectedAmount} transaction selected
            </GothamMedium>
          ) : (
            <div className="d-flex">
              {dataRendered?.map(({ title, desc }, index) => {
                const isLast = index == dataRendered.length - 1;
                const isFee = title === "Transactions Fee";
                const showFeeBreakdown =
                  isFee && someTrxAreNotCovered && isGrowthPlan;
                const showGrowthPlanFeeCalculation = isGrowthPlan && isFee;
                return (
                  <div key={index} className="d-flex">
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <GothamRegular
                          className="font12 mb-2"
                          style={{ color: jackColors.neutral100 }}
                        >
                          {title}
                        </GothamRegular>
                        {showFeeBreakdown && (
                          <FeeBreakdown
                            totalTransaction={totalTrx}
                            fee={individualFeeUnformatted}
                            freeTransaction={totalFreeTrx}
                          />
                        )}
                      </div>
                      {showGrowthPlanFeeCalculation ? (
                        <FeeCalculation {...feeCalculationProps} desc={desc} />
                      ) : (
                        <GothamMedium
                          className="font16"
                          style={{ color: jackColors.neutral100 }}
                        >
                          {desc}
                        </GothamMedium>
                      )}
                    </div>
                    {!isLast && (
                      <div
                        style={{
                          borderLeft: `1px solid ${colors.greye6}`,
                          margin: "0px 20px",
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
          <div className="d-flex" style={{ gap: 12 }}>
            <ButtonJack
              type="outline-transparent"
              children={selectedAmount ? "Cancel" : "Back"}
              style={{ minWidth: 65 }}
              onClick={() =>
                selectedAmount
                  ? UncheckedAll()
                  : onClick({ type: "back_modal" })
              }
              leftIcon={
                !selectedAmount && (
                  <JackIcons
                    name="arrow-back-outline"
                    fill="white"
                    style={{ width: 20, height: 20 }}
                  />
                )
              }
            />
            {selectedAmount ? (
              <ButtonJack
                children="Delete Transaction"
                onClick={() => onClick({ type: "open_modal_delete" })}
              />
            ) : (
              <ButtonJack
                isLoading={loading}
                // disabled={isStillThereIncomplete}
                children={isConfirmationPage ? "Submit" : "Validate"}
                style={{ minWidth: 60 }}
                onClick={() => onClick({ type: "validate" })}
                rightIcon={
                  <JackIcons
                    style={{ width: 20, height: 20 }}
                    name="arrow-forward"
                    // fill={
                    //   isStillThereIncomplete ? jackColors.neutral600 : "black"
                    // }
                    fill="black"
                  />
                }
              />
            )}
          </div>
        </div>
      </div>
      {/* {isGrowthPlan && isConfirmationPageLocal && (
        <FeeConfirmation
          totalFreeTrx={totalFreeTrx}
          bottomPosition={bottomDecider()}
        />
      )} */}
    </div>
  );
};

export const MainTitlePayroll = ({
  data,
  payrollName,
  dataIncompleteLength,
  isScreenIncomplete,
}) => {
  const { push, pathname } = useRouter();
  const isNewTransaction = pathname === "/payroll/create";
  const { isOpen, toggle } = useModalHook();

  const handleBack = () => {
    if (isNewTransaction) {
      return toggle();
    }
    return push("/payroll/create");
  };

  const handleSubmitBack = () => push("/payroll/create");

  return (
    <div>
      <BackNavigation onClick={handleBack} />
      <GothamMedium
        style={{
          color: colors.blue10,
          fontSize: 24,
          marginBottom: 16,
          marginTop: 8,
        }}
      >
        {payrollName}
      </GothamMedium>
      {isScreenIncomplete && (
        <div
          className="d-flex"
          style={{
            backgroundColor: "rgba(255, 213, 205, 0.4)",
            border: " 1px solid #E73126",
            borderRadius: 8,
            padding: "12px",
            marginBottom: 16,
          }}
        >
          <div className="pr-3">
            <img src={warningIcon} alt="icon" />
          </div>
          <div>
            <GothamMedium
              style={{
                color: colors.redca,
                lineHeight: "24px",
                marginBottom: 8,
                fontSize: 16,
              }}
            >
              {dataIncompleteLength} employee cannot be processed
            </GothamMedium>
            <GothamRegular style={{ color: colors.grey6c }}>
              This happens due to inaccuracy in the account number, Bank, or
              salary amount. Although this may be fixed later, we suggest you to
              fix it right away on the Incomplete tab.
            </GothamRegular>
          </div>
        </div>
      )}
      <ModalBackNavigation
        isOpen={isOpen}
        toggle={toggle}
        onSubmit={handleSubmitBack}
      />
    </div>
  );
};

export const ModalBackNavigation = ({ isOpen, toggle, onSubmit }) => {
  return (
    <CenteredModal isOpen={isOpen}>
      <GothamMedium
        style={{ fontSize: 24, color: colors.blue10, marginBottom: 24 }}
      >
        Are you sure?
      </GothamMedium>
      <GothamRegular style={{ fontSize: 16, marginBottom: 24 }}>
        Your all transaction will be deleted
      </GothamRegular>
      <div className="d-flex" style={{ gap: 8 }}>
        <OutlineButton
          style={{ width: "100%", borderColor: colors.greye6 }}
          onClick={toggle}
        >
          Cancel
        </OutlineButton>
        <CustomButton style={{ width: "100%" }} onClick={onSubmit}>
          Yes
        </CustomButton>
      </div>
    </CenteredModal>
  );
};
