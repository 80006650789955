import logoBri from "../../../assets/images/bank-bri-icon.svg";
import logoMandiri from "../../../assets/images/bank-mandiri-icon.svg";
import logoCimb from "../../../assets/images/bank-cimb-niaga-icon.svg";
import logoBtpn from "../../../assets/images/bank-btpn-icon.svg";
import logoPermata from "../../../assets/images/bank-permata-icon.svg";
import logoBni from "../../../assets/images/bank-bni-icon.svg";
import logoBsi from "../../../assets/images/bank-bsi-icon.svg";
import logoBca from "../../../assets/images/bank-bca-icon.svg";
import logoDanamon from "../../../assets/images/bank-danamon-icon.svg";

export const virtualAccountFormatter = (res) => {
  const data = res?.data;
  const vaReferenceIds = (data || [])
    .map((item) => item?.reference_id)
    .filter((item) => item);
  return vaReferenceIds;
};

export const virtualAccountBanksFormatter = (res) => {
  const data = res?.data;

  const bankIcon = {
    "Bank BRI": logoBri,
    "Bank Mandiri": logoMandiri,
    "Bank CIMB Niaga": logoCimb,
    "Bank Permata": logoPermata,
    "Bank BNI": logoBni,
    "Bank BTPN": logoBtpn,
    "Bank BCA": logoBca,
    "Bank BSI": logoBsi,
    "Bank Danamon": logoDanamon,
  };

  const icon16 = { width: "16px", height: "16px" };
  const icon20 = { width: "20px", height: "20px" };

  const iconSize = {
    "Bank BRI": icon16,
    "Bank Mandiri": icon20,
    "Bank CIMB Niaga": icon20,
    "Bank Permata": icon20,
    "Bank BNI": icon16,
    "Bank BTPN": icon16,
    "Bank BCA": icon16,
    "Bank BSI": icon20,
    "Bank Danamon": { ...icon20, objectFit: "contain" },
  };

  const bankOptions = (data || []).map((bank) => {
    // const bankLabel = bank.name?.split(" ")[1];
    return {
      label: bank.name,
      code: bank.code,
      value: bank.name,
      closed_min_amount: bank.min_amount,
      closed_max_amount: bank.max_amount,
      payment_config: bank.config,
      icon: bankIcon[bank.name],
      iconSize: iconSize[bank.name],
      fee: bank.fee,
    };
  });

  const bankOptionsSorted = bankOptions.sort((a, b) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();
    if (labelA < labelB) return -1;
    if (labelA > labelB) return 1;
    return 0;
  });

  return bankOptionsSorted;
};
