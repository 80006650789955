// import { useRouter } from "next/router";
// import titleCase from "title-case";

import { useRouter } from "next/router";
import { useEffect, useState } from "react";

// export const useLayout = () => {
//   const { pathname } = useRouter();

//   const hideSideBarAndNavbarArray = [
//     "/login",
//     "/",
//     "/register",
//     "/login-demo",
//     "/create-password",
//     "/kyb/basis",
//     "/forgot-password",
//   ];
//   const hideSideBarAndNavbar = hideSideBarAndNavbarArray.includes(pathname);

//   const hideSidebarArray = [
//     "/account/create-pin",
//     "/account/invite-users",
//     "/deactive",
//   ];
//   const hideSideBar =
//     pathname.includes("kyb") || hideSidebarArray.includes(pathname);
//   const isDash = pathname == "/";
//   const defaultObj = (title) => ({
//     title,
//     hideSideBarAndNavbar,
//     hideSideBar,
//   });

//   const isCreateCards = pathname.includes("/cards/create");
//   const isEditCards = pathname.includes("/cards/[id]/edit");
//   const isDeepTransactionDetails = pathname.includes("transactionId");

//   if (isCreateCards) return defaultObj("New Card");

//   if (isEditCards) return defaultObj("Edit Card");

//   if (isDeepTransactionDetails) return defaultObj("Transaction Details");

//   if (pathname.includes("[id]")) {
//     const safeSplit = pathname.split("/")[1] || "";
//     const title = safeSplit.split("-")[0] + " details";
//     return defaultObj(titleCase(title));
//   }

//   if (isDash) {
//     return defaultObj("Business Transfez Account");
//   }

//   return defaultObj(titleCase(pathname));
// };

export const useLayoutSidebars = () => {
  const { pathname } = useRouter();

  const cleanPathname = pathname.split("/")[1];

  const dynamicSettingPathnames = ["/people-management", "/category"];

  const settingPathnames = [
    "/settings-jack",
    "/dev-tools",
    "/plans",
    "/people-management",
    "/workflow",
    "/customize-receipt",
    "/dev-tools",
    "/bank-account",
    "/category",
    "/payment-cycle",
    "/notifications",
  ];

  const hasSidebarPathnames = [
    "/home",
    "/dashboard",
    "/transactions",
    "/workflow",
    "/cards",
    "/cards/statement",
    "/alerts-and-attention",
    "/people-management",
    "/invitation-request",
    "/task",
    "/virtual-account",
    "/category",
    "/calendar",
    "/va-pocket",
    "/primary-bank-account",
    ...settingPathnames,
  ];

  const hasSidebar =
    hasSidebarPathnames.find((hasSidebarPathname) => {
      const precisionWoSidebarPathnames = [
        "/cards/success",
        "/cards/onboard",
        "/cards/create",
        "/cards/pay-to-card",
        "/bank-account/create",
        "/settings-jack/change-password",
        "/settings-jack/change-pin",
        "/plans/showcase",
        "/account/create-pin",
        "/virtual-account/create",
        "/category/create",
        "/category/onboard",
        "/move-balance",
        "/register/success",
      ];

      if (precisionWoSidebarPathnames.includes(pathname)) return false;

      return hasSidebarPathname.includes(cleanPathname);
    }) && pathname !== "/";

  const isSetting =
    settingPathnames.includes(pathname) ||
    dynamicSettingPathnames.find((dynamicPathname) =>
      pathname.includes(dynamicPathname)
    );

  return { hasSidebar, isSetting, settingPathnames };
};

export const useLastScreen = () => {
  const [lastScreenObj, setLastScreenObj] = useState({});
  const { query, pathname } = useRouter();

  useEffect(() => {
    if (pathname !== lastScreenObj?.screen) return;
    setLastScreenObj({ pathname, query });
  }, [String(Object.values(query))]);

  const onClick = (pathname) => setLastScreenObj({ pathname });

  return { onClick, lastScreenObj };
};
