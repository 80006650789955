import * as Sentry from "@sentry/nextjs";
import { noCase } from "change-case";
import { init } from "commandbar";
import { get, isEmpty, pickBy } from "lodash";
import { useRouter } from "next/router";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useModalHook } from "../components/Modals";
import { booleanState } from "../components/Status";
import { isProduction, removeDuplicates } from "../components/tools";
import {
  VAPathnames,
  accountPathnames,
  activitiesORTransactionsPathnames,
  canBeAccessedAllTheTimePathnames,
  createTransactionPathnames,
} from "../constants/pathnames";
import { WelcomeModalJack } from "../pageComponents/loginPageComponents/welcomeModal";
import { apiBusiness, encryptToken, fetch, useMutation } from "../tools/api";
import { logoutEvents, setUserEvents } from "../universalFunctions/events";
import { ToasterHook } from "./ToasterContext";
import {
  loginNotRequiredPathnames,
  usePageRedirectMaster,
  useRedirectAfterLogin,
} from "./logics/authContext/pageRedirect";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";

export const userStatusBoolean = (status) => {
  const isInReview = status == "in_review";
  const isPending = status == "pending";
  const isNotSubmitted = status == "not_submitted";
  const isWaitingVerification = status == "waiting_verification";
  const showSubmitVerificationButton = isNotSubmitted || isPending;
  const isCompleted =
    status &&
    !isPending &&
    !isInReview &&
    !isNotSubmitted &&
    !isWaitingVerification;
  return {
    isCompleted,
    isInReview,
    isPending,
    isNotSubmitted,
    isWaitingVerification,
    showSubmitVerificationButton,
    status,
  };
};

export const localUserStatusBoolean = (user) => {
  const status = get(user, "partner.document_state", "");
  const booleans = userStatusBoolean(status);
  return booleans;
};

export const AuthContext = createContext({
  authorize: () => {},
  authorizeAgreed: () => {},
  unauthorize: () => {},
  token: "",
  user: {},
  userHasApiKey: false,
  isDemoUser: false,
  userLoading: false,
  refetchUser: () => {},
});

export const defaultButtons = {
  canViewAccount: false,
  canViewActivity: false,
  canSendMoney: false,
  canViewRecipients: false,
  canSeeVA: false,
  canCreateVA: false,
  canInviteUser: false,
  canCreateCard: false,
  canUpdateCard: false,
  canBlockCard: false,
  canFreezeCard: false,
  canUnfreezeCard: false,
  canUnmaskCard: false,
  canViewAllCards: false,
  isUltimate: false,
};

export const defaultButtonsReversed = {
  canViewAccount: true,
  canViewActivity: true,
  canSendMoney: true,
  canViewRecipients: true,
  canSeeVA: true,
  canCreateVA: true,
  canInviteUser: true,
  canCreateCard: true,
  canUpdateCard: true,
  canBlockCard: true,
  canFreezeCard: true,
  canUnfreezeCard: true,
  canUnmaskCard: true,
  canViewAllCards: true,
  isUltimate: true,
};

export const LSUserDeletion = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("showed_suspend_or_overdue_modal");
  localStorage.removeItem("pin-remaining-attempt");
  localStorage.removeItem("OTL");
};

export const userFormatter = (user) => {
  const {
    all_permissions = [],
    business_role = "",
    // pin: pinOld = "",
    registered_pin,
    roles = [],
    email,
    id,
  } = user || {};

  const pin = registered_pin ? "ada kok" : "";

  const isAdminStrict = Boolean(
    roles.filter(({ name }) => {
      const result = ["partner_admin", "partner_super_admin"].includes(name);
      return result;
    }).length
  );

  const isWorkflowEditor = !isEmpty(
    roles.filter(({ name }) => name.includes("editor"))
  );

  const filterer = (roleName) =>
    Boolean(roles.filter(({ name }) => name.includes(roleName)).length);

  const isBookKeeper = filterer("viewer");

  const isPayrollApprover = filterer("payroll_approver");

  const isDrafterCard = filterer("card_drafter");

  const isDrafterSendMoney = filterer("sendmoney_drafter");
  const isDrafterPayroll = filterer("payroll_drafter");
  const isDrafterInvoice = filterer("invoice_drafter");
  const isDrafterReimbursement = filterer("reimbursement_drafter");
  const isDrafterJackTransfer = filterer("balance_transfer_drafter");

  const {
    payroll_active,
    hide_beta_feature = [],
    all_flags = [],
    invoice_active,
    reimbursement_active,
    card_business,
    id: partner_id,
  } = user?.partner || {};

  const { card_business_types } = card_business || {};
  const virtualCard = card_business_types?.find(
    (e) => e.card_type === "virtual_prepaid"
  );
  const { state } = virtualCard || {};

  const isFinishedOnBoarding = state && state !== "draft";

  const permissionsArray = all_permissions
    .map(({ all_action_types, module_code }) => {
      const array = all_action_types.map((item) => ({
        ...item,
        module_code,
      }));
      return array;
    })
    .flat(1);

  let buttonBooleans = {};

  const isDurianPayDecider = () => {
    if (!email.includes("@durianpay")) return false;
    if (partner_id == 76) return false;
    return true;
  };

  const isDurianPay = isDurianPayDecider();
  const isAPIOnly = email.includes("sandboxdemo@disbursement.qa.team");

  const canViewCards =
    !all_flags.includes("SIGN_IN_SUCCESS") &&
    (isAdminStrict || isFinishedOnBoarding);

  const canViewAllCards = canViewCards && true;

  const canCreateCard = isFinishedOnBoarding && canViewCards && isDrafterCard;

  const canOnboardCard = canViewCards && isAdminStrict;

  const canCreatePin = !pin?.length;
  const canViewReport = isBookKeeper || isAdminStrict;
  const canViewEWallet = all_flags.includes("E_WALLET_ACTIVE") || isAdminStrict;
  const canViewApiDisbursement = all_flags.includes(
    "ACTIVITY_API_DISBURSEMENT"
  );
  const canViewInvoicePayment = isDrafterInvoice;
  const canViewReimbursement = isDrafterReimbursement;
  const canViewPlans = isAdminStrict;
  const canViewJackTransfer = isDrafterJackTransfer;
  const canViewDevTools = false;

  const canViewWorkflow = isAdminStrict && isWorkflowEditor;

  const canSeeVA = isAdminStrict || isBookKeeper;
  const canCreateVA = isAdminStrict || isBookKeeper; // original: admin only
  // const isUltimate = true;

  const checkerOnlyModuleCode = ({ module_code_params }) =>
    !!all_permissions.filter(
      ({ module_code }) => module_code === module_code_params
    )[0];

  const canCustomReceipt = checkerOnlyModuleCode({
    module_code_params: "CUSTOM_PREFERENCE",
  });

  const dynamicPathnames = permissionsArray
    .map(({ module_code, action, model_name }) => {
      // NOTES: this code has duality function.
      // 1. builds buttonBooleans so it can be used anywhere
      // 2. returns pathnames so it can check if user is in the right path or not
      // so for e.g you want to change `canViewPayroll`
      // and you do it like this: `const canViewPayroll = isAdmin`
      // the result is if you are the admin, you can ONLY ACCESS `/payroll` and cant go anywhere coz u stopped the logic tree
      // BUT if you can somehow turn it to FALSE or MODULE it will work just fine, cause the tree logic will keep running

      // In a nutshell: Please just use MODULES (booleanChecker) in this code OR just return false e.g: `canViewActivityFunc`

      const booleanChecker = (
        module_code_params,
        action_params,
        model_name_params
      ) =>
        noCase(module_code) == noCase(module_code_params) &&
        noCase(action) == noCase(action_params) &&
        noCase(model_name) == noCase(model_name_params);

      const canViewActivityFunc = () => {
        // if (isPayrollApprover && !isAdminStrict) return false;

        return (
          booleanChecker("ACTIVITY", "view_all_activities", "Job") ||
          booleanChecker("ACTIVITY", "view_transactions", "BusinessTransaction")
        );
      };

      const canViewActivity = canViewActivityFunc();

      const canSendMoney = isDrafterSendMoney;
      const canViewRecipients = booleanChecker(
        "SEND_MONEY",
        "view_recipients",
        "UserRecipient"
      );

      // booleanChecker("COLLECT_MONEY", "view_va", "User");

      const canSeeUsers = booleanChecker("USER_MANAGEMENT", "update", "User");
      const canInviteUser = booleanChecker("USER_MANAGEMENT", "invite", "User");
      const canViewAccount = booleanChecker(
        "WALLET_BALANCE",
        "view_balance",
        "User"
      );

      // const canCreateCard = booleanChecker("CARD", "issue_card", "UserCard");
      const canUpdateCard = booleanChecker("CARD", "assign_limit", "UserCard");
      const canBlockCard = booleanChecker("CARD", "block", "UserCard");
      const canFreezeCard = booleanChecker("CARD", "freeze", "UserCard");
      const canUnfreezeCard = booleanChecker("CARD", "unblock", "UserCard");
      const canUnmaskCard =
        booleanChecker("CARD", "unmasked_card", "UserCard") ||
        booleanChecker("CARD", "unmask_details", "UserCard");
      const canViewCardsExpenses = booleanChecker(
        "CARD",
        "view_expenses",
        "UserCard"
      );

      const isAdmin =
        isAdminStrict || String(business_role).includes("approver");

      const canViewMainBalance =
        (isAdminStrict || isBookKeeper) &&
        booleanChecker("WALLET_BALANCE", "view_balance", "User");

      const canTopup = booleanChecker(
        "WALLET_BALANCE",
        "topup",
        "TransactionActivity"
      );
      const canWithdraw = booleanChecker(
        "WALLET_BALANCE",
        "topup",
        "TransactionActivity"
      );
      const canAllocate = booleanChecker(
        "WALLET_BALANCE",
        "allocate_fund",
        "TransactionActivity"
      );
      const canViewMainBalanceHistory =
        isAdminStrict &&
        booleanChecker(
          "WALLET_BALANCE",
          "balance_history",
          "TransactionActivity"
        );
      const canViewCardBalance = booleanChecker(
        "MULTICURRENCY_BALANCE",
        "view_balance",
        "User"
      );
      const canReallocate = booleanChecker(
        "MULTICURRENCY_BALANCE",
        "reallocate",
        "TransactionActivity"
      );
      const canViewCardBalanceHistory = booleanChecker(
        "MULTICURRENCY_BALANCE",
        "balance_history",
        "TransactionActivity"
      );

      const viewPayrollDecider = () => {
        const canViewPayroll = booleanChecker(
          "PAYROLL",
          "view_payroll",
          "Payroll"
        );

        if (isAdminStrict || payroll_active) return canViewPayroll;
        return false;
      };

      const canShowPayroll = !hide_beta_feature.includes("payroll");
      const canViewPayroll = canShowPayroll && viewPayrollDecider();

      const canApprovePayroll =
        canShowPayroll &&
        booleanChecker("PAYROLL", "approve_payroll", "Payroll");
      const canRejectPayroll =
        canShowPayroll &&
        booleanChecker("PAYROLL", "reject_payroll", "Payroll");
      const canCancelPayroll =
        canShowPayroll &&
        booleanChecker("PAYROLL", "cancel_payroll", "Payroll");
      const canCreatePayroll = canShowPayroll && isDrafterPayroll;

      // buttonsPreparer
      const object = {
        canViewAccount,
        canViewActivity,
        canSendMoney,
        canViewRecipients,
        canSeeVA,
        canCreateVA,
        canSeeUsers,
        canInviteUser,
        canCreatePin,
        //cards
        canViewCardsExpenses,
        canCreateCard,
        canUpdateCard,
        canBlockCard,
        canFreezeCard,
        canUnfreezeCard,
        canUnmaskCard,
        canViewCards,
        canViewAllCards,
        //cards
        // home props
        canViewReport,
        canViewWorkflow,
        canViewMainBalance,
        canTopup,
        canWithdraw,
        canAllocate,
        canViewMainBalanceHistory,
        canViewCardBalance,
        canReallocate,
        canViewCardBalanceHistory,
        // home props
        // payrolls
        canViewPayroll,
        canCreatePayroll,
        canApprovePayroll,
        canRejectPayroll,
        canCancelPayroll,
        // payrolls
        //e-wallet
        canViewEWallet,
        //e-wallet
        canViewInvoicePayment,
        canViewReimbursement,
        canViewApiDisbursement,
        canViewSettings: true,
        canViewHistory: true,
        canViewTasks: true,
        canCustomReceipt,
      };

      const keys = Object.keys(object);

      keys.map((key) => {
        const value = object[key];
        if (!value) return;
        buttonBooleans[key] = value;
      });

      // strict urls
      // payroll
      if (canViewPayroll) return ["/payroll"];
      if (canCreatePayroll) return ["/payroll/create"];
      if (canCreatePayroll && payroll_active) return ["/payroll/invite"];
      // payroll

      if (canUpdateCard) return ["/cards/[id]/edit"];
      // strict urls

      // buttonsPreparer
      if (canViewAccount) return accountPathnames;
      if (canViewActivity) return activitiesORTransactionsPathnames;
    })
    .filter((val) => val)
    .flat(1);

  const canViewLocalTransfer = !hide_beta_feature.includes("local_transfer");

  const createPin = canCreatePin ? ["/account/create-pin"] : [];
  const viewReport = canViewReport ? ["/report-download"] : [];
  const eWallet = canViewEWallet ? ["/e-wallet"] : [];
  const workflow = canViewWorkflow ? ["/workflow"] : [];
  const invoicePayment = canViewInvoicePayment ? ["/invoice-payment"] : [];
  const apiDisbursement = canViewApiDisbursement ? ["/histories-api"] : [];
  const reimbursement = canViewReimbursement ? ["/reimbursement"] : [];
  const plans = canViewPlans ? ["/plans"] : [];
  const jackTransfer = canViewJackTransfer ? ["/jack-transfer"] : [];

  const viewCard = canViewCards ? ["/cards"] : [];
  const createCard = canCreateCard ? ["/cards/create"] : [];
  const initiateCard = canOnboardCard ? ["/cards/onboard"] : [];
  const customReceipt = canCustomReceipt ? ["/customize-receipt"] : [];
  // const ultimate = isUltimate
  //   ? ["/move-balance", "/va-pocket", "/primary-bank-account"]
  //   : [];
  const devTools = canViewDevTools ? ["/dev-tools"] : [];

  const va = canSeeVA ? VAPathnames : [];
  const createVA = canCreateVA ? ["/virtual-account/create"] : [];
  const sendMoney = isDrafterSendMoney ? createTransactionPathnames : [];

  const pathnamesDecider = () => {
    const activePathnames = removeDuplicates([
      ...createVA,
      ...va,
      ...sendMoney,
      ...canBeAccessedAllTheTimePathnames,
      ...dynamicPathnames,
      ...createPin,
      ...viewReport,
      ...eWallet,
      ...workflow,
      ...invoicePayment,
      ...apiDisbursement,
      ...reimbursement,
      ...viewCard,
      ...createCard,
      ...initiateCard,
      ...customReceipt,
      ...plans,
      ...jackTransfer,
      // ...ultimate,
      ...devTools,
    ]);
    buttonBooleans = { ...buttonBooleans, canViewLocalTransfer };

    const durianPayHiddenPathnames = [
      "/cards",
      "/e-wallet",
      "/payroll",
      "/invoice-payment",
      "/users",
      "/users/edit-workflow",
      "/reimbursement",
      "/cards/create",
    ];

    const defaultHiddenPathnames = [...durianPayHiddenPathnames, "/workflow"];

    const durianPayHiddenBooleans = [
      "canAllocate",
      "canApprovePayroll",
      "canCancelPayroll",
      "canInviteUser",
      "canRejectPayroll",
      "canSeeUsers",
      "canViewPayroll",
      "canViewCards",
      "canViewEWallet",
      "canViewInvoicePayment",
      "canViewAllCards",
      "canViewReimbursement",
    ];

    const defaultHiddenButtonBooleans = [
      ...durianPayHiddenBooleans,
      "canViewWorkflow",
    ];

    if (isDurianPay) {
      buttonBooleans = pickBy(
        buttonBooleans,
        (_, key) =>
          ![
            ...durianPayHiddenBooleans,
            "canViewTasks",
            "canViewHistory",
            "canSendMoney",
          ].includes(key)
      );
      const filteredPathnames = activePathnames.filter(
        (key) => !durianPayHiddenPathnames.includes(key)
      );

      return filteredPathnames;
    }

    if (isAPIOnly) {
      const hiddenPathnames = [
        ...defaultHiddenPathnames,
        "/menu-api",
        "/local-transfer",
        "/local-disbursement",
        "/settings",
      ];
      const hiddenButtonBooleans = [
        ...defaultHiddenButtonBooleans,
        "canViewDevTools",
        "canViewSettings",
        "canSendMoney",
        "canViewHistory",
        "canViewTasks",
      ];
      buttonBooleans = {
        ...pickBy(
          buttonBooleans,
          (_, key) => !hiddenButtonBooleans.includes(key)
        ),
        canViewApiDisbursement: true,
      };
      const filteredPathnames = [
        ...activePathnames.filter((key) => !hiddenPathnames.includes(key)),
        "/histories-api",
      ];
      return filteredPathnames;
    }

    return activePathnames;
  };

  const activePathnames = pathnamesDecider();

  // customize user props
  const globalRoles = get(user, "roles", []).filter((role) => {
    const roleName = get(role, "name", "");
    const beOnlyRoles = ["admin", "checker", "maker"];
    return !beOnlyRoles.includes(roleName);
  });

  const role = globalRoles[0]?.name || "";
  const isPunyUser = ["partner_viewer", "partner_card_user"].includes(role);
  const isAdmin = !isPunyUser;
  const mainBalance = (user?.partner?.disbursement_balance || [])[0] || {};

  const { partner } = user || {};
  const {
    document_state: document_stateRaw,
    director_basis_id,
    business_type,
  } = partner || {};

  const isIndividual = business_type == "individual";

  const { isWaitingVerification } = booleanState(document_stateRaw);

  const document_state =
    isWaitingVerification && !director_basis_id && !isIndividual
      ? "not_submitted"
      : document_stateRaw;
  // customize user props

  const logoutTime = new Date().getTime() + 55 * 60 * 1000; // in 55 min, auto kick
  return {
    isDurianPay,
    isAPIOnly,
    activePathnames,
    buttonBooleans,
    role,
    isAdmin,
    mainBalance,
    logoutTime,
    ...user,
    pin,
    partner: {
      ...partner,
      // document_state,
      real_document_state: document_stateRaw,
    },
  };
};

export const AuthProvider = ({ children }) => {
  const {
    data: user,
    setData: setUser,
    refetch: refetchUserData,
    loading: userLoading,
  } = fetch({
    url: "/my_account",
    woInit: true,
    additionalFetch: async () => {
      const { data } = await apiBusiness.get("/card_business_detail");

      return data?.data;
    },
    formatter: (data, _, secondData) => {
      const newData = data.data.partner.card_business;

      const partner = {
        ...(data?.data.partner || {}),
        card_business: { ...newData, ...secondData },
      };

      const realUser = { ...data?.data, partner };
      const user = userFormatter(realUser || {});

      const { email } = user;

      window?.OpenReplay?.setUserID(email);

      localStorage.setItem("user", JSON.stringify(user));
      return user;
    },
    defaultValue: {},
    noToaster: false,
  });

  const refetchUser = () => {
    refetchUserData();
  };

  const setsUser = (user) => {
    setUser(user);
    localStorage.setItem("user", JSON.stringify(user));
  };

  const { pathname, push } = useRouter();

  useEffect(() => {
    const isExcluded = loginNotRequiredPathnames.includes(pathname);
    if (isExcluded) return;

    if (localStorage.getItem("user") || pathname == "/_error")
      return localStorage.setItem("pathnameStorage", "");

    localStorage.setItem("pathnameStorage", pathname);
  }, [pathname]);

  const { redirectAfterLogin } = useRedirectAfterLogin();

  usePageRedirectMaster({
    user,
  });

  const { email, partner } = user || {};

  const userHasApiKey = Boolean(partner?.live_api_key);

  // const isWelcomeModalOpen = !is_agreed;

  const isDemoUser = email == "transfezdemo@gmail.com";

  const authorizeAgreed = async ({ is_agreed }) => {
    const result = { ...user, is_agreed };
    setsUser(result);
  };

  useEffect(() => {
    // !isProduction && init("37b3c2bb");
    init("37b3c2bb");
  }, []);

  const startCommandBar = () => {
    // if (isProduction) return;
    window.CommandBar.boot(user?.id);
    window.CommandBar.addRouter((url) => push(url));
  };

  const stopCommandBar = () => {
    // !isProduction && window.CommandBar.shutdown();
    window.CommandBar.shutdown();
  };

  const unauthorize = () => {
    LSUserDeletion();
    setUser({});
    Sentry.configureScope((scope) => scope.setUser(null));
    logoutEvents(user);
    stopCommandBar();
  };

  const { errorToaster, warningToaster } = ToasterHook();

  const [tempPassword, setTempPassword] = useState(false);

  const { isOpen, toggle } = useModalHook();

  const { getData } = useVisitorData(
    { ignoreCache: true },
    { immediate: false }
  );

  const authorize = async ({
    token,
    setIsLoggedIn,
    tempPassword,
    handleRedirect,
  }) => {
    try {
      //sets token
      const encryptedToken = encryptToken(token);
      localStorage.setItem("token", encryptedToken);
      //sets token

      //sets user
      const { data } = await apiBusiness.get("/my_account");

      const isAgreedNone = !data?.data?.is_agreed;

      if (isAgreedNone) {
        const { requestId: salus } = await getData();
        await apiBusiness.put("/update_account", { is_agreed: true, salus });
      }

      const user = userFormatter(data?.data || {});
      const { email, id, partner, is_verified } = user;
      if (!is_verified) {
        setIsLoggedIn && setIsLoggedIn(false);
        unauthorize();
        return errorToaster(
          "Error!",
          `Please check your email ${email} to access Jack`
        );
      }
      Sentry.setUser({ email, id, username: get(partner, "name") });
      setsUser(user);
      startCommandBar();
      //sets user

      setTempPassword(tempPassword);

      const storageKey = "hasOpenedNew";
      const hasOpened = localStorage.getItem(storageKey);
      !hasOpened && toggle();
      // localStorage.setItem(storageKey, "true");

      localStorage.setItem("popup", "true");

      if (handleRedirect) return handleRedirect();

      redirectAfterLogin(user);
      setUserEvents(user);
    } catch (err) {
      console.log("err:", err);
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!isEmpty(user)) startCommandBar();

    setUser(user);
  }, []);

  const { isWaitingVerification } = userStatusBoolean(
    user?.partner?.document_state
  );

  const stopperPathnames = [
    "/",
    "/login",
    "/create-password",
    "/register",
    "/dashboard",
    "/new-register",
  ];
  const noRefetch = stopperPathnames.includes(pathname);
  // used in KYB so it can updates user state after user submits data

  useEffect(() => {
    if (!isWaitingVerification) return;
    if (noRefetch) return;

    let interval = setInterval(async () => refetchUser(), 5000);
    return () => clearInterval(interval);
  }, [isWaitingVerification]);
  // used in KYB

  useEffect(() => {
    if (isEmpty(user)) return;
    // needs setTimeout coz for some reason, moengage not ready yet if u call directly
    setTimeout(() => setUserEvents(user), 2000);
  }, [isEmpty(user)]);

  // auto logout timeout handler
  const { logoutTime } = user || {};

  useEffect(() => {
    if (!logoutTime) return;

    let interval = setInterval(async () => {
      const now = new Date().getTime();
      if (now > logoutTime) {
        unauthorize();
        push("/login");
        warningToaster({ msg: "Your session is expired, please login again" });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [logoutTime]);
  // auto logout timeout handler

  // refetch every change page
  useEffect(() => {
    const isRegister =
      pathname.includes("/register") || pathname.includes("/new-register");
    if (noRefetch || isRegister) return;
    refetchUser();
  }, [pathname]);
  // refetch every change page

  const values = {
    authorize,
    authorizeAgreed,
    unauthorize,
    user,
    userHasApiKey,
    isDemoUser,
    userLoading,
    refetchUser,
    tempPassword,
    resetTempPassword: () => setTempPassword(false),
  };

  return (
    <AuthContext.Provider value={values}>
      {children}
      <WelcomeModalJack isOpen={isOpen} toggle={toggle} />
    </AuthContext.Provider>
  );
};

export const useUserFlags = (props = { woAutoRefetchUser: false }) => {
  const { woAutoRefetchUser } = props;

  const { user, userLoading, refetchUser } = useGetAuth();

  const userId = user?.id;
  const userFlags = user?.all_flags ?? [];

  const { mutation: addUserFlag, loading: isLoadingAddUserFlag } = useMutation({
    method: "post",
    url: `/business_users/${userId}/create_user_flag`,
    afterSuccess: () => {
      if (woAutoRefetchUser) return;
      refetchUser();
    },
  });

  const handleAddUserFlag = async (flag) => {
    const isExist = userFlags.includes(flag);
    const canAddUserFlag = !isExist && userId;
    if (!canAddUserFlag) return;

    await addUserFlag({ flags: flag });
  };

  return {
    userFlags,
    isLoadingUserFlags: userLoading,
    isLoadingAddUserFlag,
    hasUser: !isEmpty(user),
    addUserFlag: handleAddUserFlag,
  };
};

export const useGetAuth = () => {
  const {
    authorize,
    authorizeAgreed,
    unauthorize,
    user,
    userHasApiKey,
    isDemoUser,
    userLoading,
    refetchUser,
    tempPassword,
    resetTempPassword,
  } = useContext(AuthContext) || {};
  return {
    authorize,
    authorizeAgreed,
    unauthorize,
    user,
    userHasApiKey,
    isDemoUser,
    userLoading,
    refetchUser,
    tempPassword,
    resetTempPassword,
  };
};

export const JACK_TRANSFER_VALUE = "/jack-transfer";
export const CROSS_BORDER_VALUE = "/cross-border";
export const LOCAL_TRANSFER_VALUE = "/local-transfer/create";
export const REIMBURSEMENT_VALUE = "/reimbursement/create?step=input-name";
export const BILL_VALUE = "/invoice-payment/create?step=upload";
export const PAYROLL_VALUE = "/payroll/create";
export const CARD_VALUE = "/cards/create";
export const VA_VALUE = "/virtual-account/create";
export const VA_SIDEBAR_VALUE = "Virtual Account";
export const TOPUP_VALUE = "topup";

export const INTERNATIONAL_AUTH_ENUM = "international";
export const LOCAL_AUTH_ENUM = "local";
export const BILL_AUTH_ENUM = "bill";
export const REIMBURSEMENT_AUTH_ENUM = "reimbursement";
export const PAYROLL_AUTH_ENUM = "payroll";
export const CARD_AUTH_ENUM = "card";
export const TOPUP_AUTH_ENUM = "topup";
export const VA_AUTH_ENUM = "va";

export const useActiveModules = (
  args = { isOpenCreateDropdown: false, module: "" }
) => {
  const { isOpenCreateDropdown, module } = args ?? {};

  const { push } = useRouter();

  const { user } = useGetAuth();
  const { partner } = user ?? {};
  const { active_services } = partner ?? {};

  const {
    international_transfer,
    local_transfer,
    bill_payment,
    reimbursement,
    payroll,
    card,
    topup,
    va_collection,
  } = active_services ?? {};

  const activeModules = {
    isActiveInternationalTransfer: Boolean(international_transfer),
    isActiveLocalTransfer: Boolean(local_transfer),
    isActiveBillPayment: Boolean(bill_payment),
    isActiveReimbursement: Boolean(reimbursement),
    isActivePayroll: Boolean(payroll),
    isActiveCard: Boolean(card),
    isActiveTopup: Boolean(topup),
    isActiveVA: Boolean(va_collection),
  };

  const {
    isActiveInternationalTransfer,
    isActiveLocalTransfer,
    isActiveBillPayment,
    isActiveReimbursement,
    isActivePayroll,
    isActiveCard,
    isActiveTopup,
    isActiveVA,
  } = activeModules;

  const isDisabledReleaseDecider = ({ module = "" }) => {
    const {
      isActiveInternationalTransfer,
      isActiveLocalTransfer,
      isActiveReimbursement,
      isActiveBillPayment,
      isActivePayroll,
    } = activeModules ?? {};

    const {
      isCrossBorder,
      isLocalTransfer,
      isLocalDisbursementBatch,
      isLocalDisbursement,
      isLocalDisbursements,
      isLocalTransferSingle,
      isReimbursement,
      isInvoice,
      isInvoicePayment,
      isPayroll,
    } = booleanState(module);

    const isLocalTransferType =
      isLocalTransfer ||
      isLocalDisbursementBatch ||
      isLocalDisbursement ||
      isLocalDisbursements ||
      isLocalTransferSingle;
    const isBillType = isInvoice || isInvoicePayment;

    if (isCrossBorder && !isActiveInternationalTransfer) {
      return true;
    }
    if (isLocalTransferType && !isActiveLocalTransfer) {
      return true;
    }
    if (isReimbursement && !isActiveReimbursement) {
      return true;
    }
    if (isBillType && !isActiveBillPayment) {
      return true;
    }
    if (isPayroll && !isActivePayroll) {
      return true;
    }

    return false;
  };

  const [isAllowOverflow, setIsAllowOverflow] = useState(false);
  const [isTolerateUnhover, setIsTolerateUnhover] = useState(true);
  const [showInactiveModuleTooltip, setShowInactiveModuleTooltip] =
    useState("");

  const tolerateUnhover = () => setIsTolerateUnhover(true);
  const intolerateUnhover = () => setIsTolerateUnhover(false);
  const resetModuleTooltip = () => setShowInactiveModuleTooltip("");

  const handleUnhoverModuleOption = () => {
    tolerateUnhover();
  };

  const handleHoverModuleOption = (module = "") => {
    intolerateUnhover();
    if (module !== showInactiveModuleTooltip) resetModuleTooltip();

    const isInternationalTransfer = module === CROSS_BORDER_VALUE;
    const isLocalTransfer = module === LOCAL_TRANSFER_VALUE;
    const isReimbursement = module === REIMBURSEMENT_VALUE;
    const isBillPayment = module === BILL_VALUE;
    const isPayroll = module === PAYROLL_VALUE;
    const isCard = module === CARD_VALUE;
    const isVA = module === VA_VALUE;
    const isVASidebar = module === VA_SIDEBAR_VALUE;
    const isTopup = module === TOPUP_VALUE;

    const setModule = () => setShowInactiveModuleTooltip(module);

    if (isInternationalTransfer && !isActiveInternationalTransfer) {
      setModule();
      return;
    }
    if (isLocalTransfer && !isActiveLocalTransfer) {
      setModule();
      return;
    }
    if (isReimbursement && !isActiveReimbursement) {
      setModule();
      return;
    }
    if (isBillPayment && !isActiveBillPayment) {
      setModule();
      return;
    }
    if (isPayroll && !isActivePayroll) {
      setModule();
      return;
    }
    if (isCard && !isActiveCard) {
      setModule();
      return;
    }
    if (isVA && !isActiveVA) {
      setModule();
      return;
    }
    if (isVASidebar && !isActiveVA) {
      setModule();
      return;
    }
    if (isTopup && !isActiveTopup) {
      setModule();
      return;
    }
  };

  useEffect(() => {
    if (!isTolerateUnhover) return;
    const timeout = setTimeout(() => {
      resetModuleTooltip();
    }, 500);
    return () => clearTimeout(timeout);
  }, [isTolerateUnhover]);

  useEffect(() => {
    if (!isOpenCreateDropdown) {
      setIsAllowOverflow(false);
      return;
    }
    const timeout = setTimeout(() => {
      setIsAllowOverflow(true);
    }, 500);
    return () => clearTimeout(timeout);
  }, [isOpenCreateDropdown]);

  useEffect(() => {
    const kick = () => push("/dashboard");

    switch (module) {
      case INTERNATIONAL_AUTH_ENUM:
        if (!isActiveInternationalTransfer) kick();
        break;
      case LOCAL_AUTH_ENUM:
        if (!isActiveLocalTransfer) kick();
        break;
      case BILL_AUTH_ENUM:
        if (!isActiveBillPayment) kick();
        break;
      case REIMBURSEMENT_AUTH_ENUM:
        if (!isActiveReimbursement) kick();
        break;
      case PAYROLL_AUTH_ENUM:
        if (!isActivePayroll) kick();
        break;
      case CARD_AUTH_ENUM:
        if (!isActiveCard) kick();
        break;
      case TOPUP_AUTH_ENUM:
        if (!isActiveTopup) kick();
        break;
      case VA_AUTH_ENUM:
        if (!isActiveVA) kick();
        break;
      default:
        break;
    }
  }, [
    module,
    isActiveInternationalTransfer,
    isActiveLocalTransfer,
    isActiveBillPayment,
    isActiveReimbursement,
    isActivePayroll,
    isActiveCard,
    isActiveTopup,
    isActiveVA,
  ]);

  return {
    ...activeModules,
    isDisabledReleaseDecider,
    isAllowOverflow,
    showInactiveModuleTooltip,
    handleHoverModuleOption,
    handleUnhoverModuleOption,
  };
};

export const getBusinessFlags = () => {
  const { user } = useGetAuth();
  const { all_flags = [] } = user?.partner || {};
  const isEWalletActivated = all_flags.includes("E_WALLET_ACTIVE");

  return { isEWalletActivated };
};

export const getUserStatusBoolean = () => {
  const { user } = useContext(AuthContext);
  const status = get(user, "partner.document_state", "");
  const booleans = userStatusBoolean(status);
  return booleans;
};

export const getUserButtonBooleans = () => {
  // how to use?
  // first, just use defaultButtonsReversed or defaultButtons

  // example:
  // const {canSeeVA} = defaultButtonsReversed

  // then after you pick the buttons
  // just swap it with this function

  // example:
  // const {canSeeVA} = getUserButtonBooleans()

  const { user } = useContext(AuthContext);
  const buttonBooleans = user?.buttonBooleans || {};
  return buttonBooleans;
};

export const useInvalidStatus = () => {
  const { isCompleted } = getUserStatusBoolean();
  const { isOpen, toggle } = useModalHook();

  const invalidUserStatus = () => {
    if (!isCompleted) {
      toggle && toggle();
      return true;
    }
  };

  return { invalidUserStatus, isOpen, toggle };
};

export const getRolesUserLogin = (roles) => {
  if (!roles) return {};

  const filterer = (string) =>
    roles.filter(({ name }) => name.includes(string)).length > 0;

  const isDrafter = filterer("payroll_drafter");
  const isApprover = filterer("payroll_approver");
  const isAdmin = filterer("partner_admin");
  const isSuperAdmin = filterer("partner_super_admin");
  const isNotified = filterer("payroll_notified");
  const isPayroll = filterer("payroll");
  const isDrafterCard = filterer("card_drafter");
  const isDrafterSendMoney = filterer("sendmoney_drafter");
  const isDrafterPayroll = filterer("payroll_drafter");
  const isDrafterInvoice = filterer("invoice_drafter");
  const isDrafterReimbursement = filterer("reimbursement_drafter");
  const isDrafterBalanceManagement = filterer("balance_management_drafter");

  return {
    isDrafterSendMoney,
    isDrafterReimbursement,
    isDrafterInvoice,
    isDrafterCard,
    isDrafter,
    isApprover,
    isAdmin,
    isNotified,
    isSuperAdmin,
    isPayroll,
    isDrafterPayroll,
    isDrafterBalanceManagement,
  };
};

export const getUserRoleBooleans = ({ roles, role, pin, managed_teams }) => {
  const {
    isAdmin: isAdminFromRoles,
    isSuperAdmin: isSuperAdminFromRoles,
    isApprover,
    isDrafter,
    isNotified,
    isDrafterInvoice,
    isPayroll,
    isDrafterReimbursement,
    isDrafterCard,
    isDrafterSendMoney,
    isDrafterPayroll,
    isDrafterBalanceManagement,
  } = getRolesUserLogin(roles || []);

  const isSuperAdmin = role === "partner_super_admin" || isSuperAdminFromRoles;
  const isAdmin = role === "partner_admin" || isAdminFromRoles;
  const isBookKeeper = role === "partner_viewer";
  const isEmployee = role === "partner_maker";
  const roleName = isSuperAdmin
    ? "Business Owner"
    : isAdmin
    ? "Admin"
    : isBookKeeper
    ? "Bookkeeper"
    : "Employee";
  const isHasPin = Boolean(pin);

  const isAdminOrSuperAdmin = isAdmin || isSuperAdmin;

  const isManagingTeams = (managed_teams ?? []).length > 0;

  const isEmployeeManager = isEmployee && isManagingTeams;
  const isBookKeeperManager = isBookKeeper && isManagingTeams;

  const isWeakManager = isEmployeeManager || isBookKeeperManager;

  return {
    isDrafterInvoice,
    isDrafterReimbursement,
    isDrafterCard,
    isSuperAdmin,
    isAdmin,
    isAdminOrSuperAdmin,
    isBookKeeper,
    isEmployee,
    isApprover,
    isDrafter,
    roleName,
    isHasPin,
    isNotified,
    isPayroll,
    isManagingTeams,
    isEmployeeManager,
    isBookKeeperManager,
    isWeakManager,
    managedTeams: managed_teams,
    isDrafterSendMoney,
    isDrafterPayroll,
    isDrafterBalanceManagement,
  };
};

export const getUserRole = () => {
  const { user } = useContext(AuthContext);

  return getUserRoleBooleans(user || {});
};
