import { titleCase } from "change-case";
import { Fragment, useState } from "react";
import { useForm } from "@tools";
import { colors, jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { Avatar } from "../../../components/Avatar";
import { CustomButton, OutlineButton } from "../../../components/Buttons";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import {
  MainCardHeaderMolecule,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../components/CardModal";
import { CustomTextArea } from "../../../components/inputs";
import { SimpleTextAreaJack } from "../../../components/inputs/simpleTextArea";
import { RightModal } from "../../../components/Modals/RightModal/parent";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
} from "../../../components/Text";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { WrapperModalButtons } from "../../../pageComponents/homeComponents/topup/components";
import { CardListSuccess } from "../../../pageComponents/successComponents/internationalSingle";
import { DetailsModal } from "../crossBorderSingleModal/components";
import PINModal from "../../../components/PINModal/parent";
import { useModalHook } from "../../../components/Modals";
import Skeleton from "react-loading-skeleton";

const RejectBackButtons = ({
  onClick,
  onClickBack,
  loading,
  disableSubmit,
  type = "reject",
  customButtonText = "",
}) => {
  const buttonText = type == "reject" ? "Reject" : "Cancel";
  const text = customButtonText ? customButtonText : `${buttonText} Request`;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        width: "100%",
      }}
    >
      <ButtonJack
        type="filled"
        onClick={onClick}
        isLoading={loading}
        disabled={disableSubmit}
      >
        {text}
      </ButtonJack>
      <ButtonJack
        type="outline"
        style={{ backgroundColor: "transparent" }}
        onClick={onClickBack}
      >
        Back
      </ButtonJack>
    </div>
  );
};

export const ModalCardLeftRight = ({ data, containerStyle, mainDivStyle }) => {
  const {
    iconName,
    title,
    recipient_name,
    array,
    customHeader,
    customDesc,
    customIcon,
    loading,
  } = data || {};
  return (
    <div style={{ ...containerStyle, width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 16,
          border: "1px solid #E6E6E8",
          borderRadius: 4,
          ...mainDivStyle,
        }}
      >
        {customHeader && (
          <div className="d-flex align-items-center">
            {customIcon ? (
              customIcon
            ) : (
              <Avatar
                style={{ marginRight: 8 }}
                size="medium"
                mainIcon={
                  <JackIcons
                    name={iconName}
                    style={{ width: 20, height: "auto" }}
                  />
                }
              />
            )}
            <div className="d-flex-column justify-content-between">
              <GothamMedium className="font12">{title}</GothamMedium>
              <GothamRegular
                className="font12"
                style={{ color: colors.grey90, lineHeight: "16px" }}
              >
                {!!customDesc ? customDesc : `Transfer to ${recipient_name}`}
              </GothamRegular>
            </div>
          </div>
        )}
        {loading ? (
          <div className="mt-3">
            <Skeleton
              count={4}
              width={"100%"}
              height={20}
              style={{ marginTop: 8 }}
            />
          </div>
        ) : (
          <DetailsModal
            array={array}
            containerStyle={{ padding: 0, border: "0px solid transparent" }}
          />
        )}
        {/* <div
          style={{ width: "100%", height: 1, backgroundColor: colors.greye6 }}
        />
        <div className="d-flex justify-content-between w-100">
          <GothamRegular className="font12" style={{ color: colors.grey6c }}>
            Total amount
          </GothamRegular>
          <GothamRegular className="font12" style={{ color: colors.grey6c }}>
            {totalAmount}
          </GothamRegular>
        </div>
        <div className="d-flex justify-content-between w-100">
          <GothamRegular className="font12" style={{ color: colors.grey6c }}>
            Handling fee
          </GothamRegular>
          <GothamRegular className="font12" style={{ color: colors.grey6c }}>
            {fee || "IDR 0"}
          </GothamRegular>
        </div>
        <div
          style={{ width: "100%", height: 1, backgroundColor: colors.greye6 }}
        />
        <div className="d-flex justify-content-between w-100">
          <GothamRegular className="font12" style={{ color: colors.grey6c }}>
            Final amount
          </GothamRegular>
          <GothamMedium
            className="font12"
            style={{ color: jackColors.neutral900 }}
          >
            {totalPayment}
          </GothamMedium>
        </div> */}
      </div>
    </div>
  );
};

export const PayrollRejectModal = ({
  text,
  isOpen,
  toggle,
  loading = false,
  onSubmit: onSubmitProps,
  detailData,
  customHeadline = "",
  customSubtitle = "",
  customButtonText = "",
}) => {
  // const resolver = schemaGenerator({ stringArr: ["reason"] });t
  const {
    isOpen: isOpenPINModal,
    open: openPINModal,
    close: closePINModal,
  } = useModalHook();
  const { errorSnackBar } = ToasterHook();
  const useFormObj = useForm();
  const { handleSubmit, watch } = useFormObj;
  const [middleHeight, setMiddleHeight] = useState(0);
  const placeholder = `Reasons for ${text}`;
  const isCancel = text == "cancellation" || text == "cancelation";

  const type = isCancel ? "cancel" : "reject";
  const headline = isCancel ? "Cancel" : "Reject";

  const defaultHeadline = `${headline} this request?`;
  const watchReason = watch("reason");
  const isReasonEmpty = !Boolean(watchReason);

  const buttonTextDecider = () => {
    if (customButtonText) return customButtonText;
    return isCancel ? "Cancel Transaction" : "Reject Request";
  };

  const buttonText = buttonTextDecider();

  const onSubmit = (values) => {
    if (!Boolean(watchReason)) return;
    const { reason: reasonRaw, pin } = values;
    const dotsRemoved = String(reasonRaw).split(".").join("");
    const firstRegex = dotsRemoved.replace(/\n|\r/g, " ");
    const reason = firstRegex.replace(/  +/g, " ").trim();
    const isEmpty = !reason.split(" ").join("");
    if (!reason || isEmpty)
      return errorSnackBar({ msg: `${placeholder} cannot be empty` });

    onSubmitProps({ reason, pin }, type);
  };
  ////////////////////////////////
  return (
    <Fragment>
      <PINModal
        isLoading={loading}
        isOpen={isOpenPINModal}
        toggle={() => {
          closePINModal();
        }}
        onBack={() => {
          closePINModal();
        }}
        onSubmit={({ pin }) =>
          handleSubmit((values) => onSubmit({ ...values, pin }))()
        }
      />
      <RightModal
        isOpen={isOpen}
        toggle={toggle}
        noPadding={true}
        width={[420]}
        array={[
          <WrapperModalButtons
            childrenButton={
              <RejectBackButtons
                type={type}
                customButtonText={buttonText}
                onClick={openPINModal}
                submitLoading={loading}
                onClickBack={toggle}
                disableSubmit={isReasonEmpty}
              />
            }
            containerStyle={{ padding: 20, width: 421 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: 16,
                width: 420,
                gap: 32,
              }}
            >
              <div className="d-flex-column">
                <GothamMedium style={{ fontSize: 24, marginBottom: 0 }}>
                  {customHeadline ? customHeadline : defaultHeadline}
                </GothamMedium>
                {customSubtitle && (
                  <GothamRegular style={{ marginTop: 8, letterSpacing: "0.1" }}>
                    {customSubtitle}
                  </GothamRegular>
                )}
              </div>
              <ModalCardLeftRight data={detailData} />
              <SimpleTextAreaJack
                name="reason"
                placeholder="E.g., this request contains incorrect data"
                label={
                  <>
                    Reason of {titleCase(text)}
                    <span style={{ color: jackColors.redDC }}>*</span>
                  </>
                }
                useFormObj={useFormObj}
                style={{
                  width: "100%",
                  height: 120,
                }}
              />
            </div>
          </WrapperModalButtons>,
        ]}
      />
    </Fragment>
  );
  // return (
  //   <RightCardModalAtomic
  //     isOpen={isOpen}
  //     middleHeight={middleHeight}
  //     paddingTop={12}
  //     header={
  //       <MainCardHeaderMolecule
  //         isClose={false}
  //         toggle={toggle}
  //         customComponent={
  //           <div style={{ width: "100%" }}>
  //             <GothamMedium
  //               style={{ color: "#fcfcfc", marginBottom: 16 }}
  //               className="font24"
  //             >
  //               Give a reason for {text}
  //             </GothamMedium>
  //             <CustomTextArea
  //               name="reason"
  //               placeholder={placeholder}
  //               useFormObj={useFormObj}
  //               style={{
  //                 width: "100%",
  //                 height: 84,
  //                 marginBottom: 16,
  //               }}
  //             />
  //           </div>
  //         }
  //       />
  //     }
  //     middle={
  //       <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
  //         <CustomButton
  //           className="w-100"
  //           onClick={handleSubmit(onSubmit)}
  //           isLoading={loading}
  //           style={{ cursor: isReasonEmpty && "no-drop" }}
  //         >
  //           Submit
  //         </CustomButton>
  //       </MiddleWrapper>
  //     }
  //     body={
  //       <OutlineButton className="w-100" onClick={toggle}>
  //         Cancel
  //       </OutlineButton>
  //     }
  //   />
  // );
};
