import { createContext, useContext, useEffect, useState } from "react";
import ReactJoyride, { STATUS, ACTIONS } from "react-joyride";
import { isArray, isEmpty, isFunction, isObject } from "lodash";
import { useRouter } from "next/router";

export const GuidedTourContext = createContext({
  data: {
    steps: [],
    props: {},
    callback: null,
    stepIndex: 0,
    tourPathnames: [],
  },
  startTour: () => {},
  stopTour: () => {},
});

const defaultState = {
  isRunning: false,
  steps: [],
  props: {},
  callback: null,
  stepIndex: 0,
  pages: [],
};

const GuidedTourProvider = ({ children = null }) => {
  const [
    { isRunning, steps, props, callback, stepIndex, tourPathnames },
    setGuidedTourState,
  ] = useState(defaultState);

  const stopTour = () => {
    setGuidedTourState(defaultState);
  };

  const startTour = ({ steps, props, callback, stepIndex, tourPathnames }) => {
    const hasSteps = isArray(steps) && steps?.length > 0;
    const hasProps = isObject(props) && !isEmpty(props);

    if (!hasSteps) return;

    const state = {
      isRunning: true,
      steps,
      props: {},
      callback,
      stepIndex,
      tourPathnames,
    };

    if (hasProps) state.props = props;

    setGuidedTourState(state);
  };

  const handleCallback = (data) => {
    if (isFunction(callback)) {
      return callback({
        data,
        defaultState,
        setGuidedTourState,
      });
    }

    const { action, status } = data;
    const isClosed = action === ACTIONS.CLOSE;
    const isFinished = status === STATUS.FINISHED;
    if (isFinished || isClosed) setGuidedTourState(defaultState);
  };

  const providerValue = {
    data: {
      isRunning,
      steps,
      props,
      callback,
      stepIndex,
      tourPathnames,
    },
    startTour,
    stopTour,
  };

  const { pathname } = useRouter();

  useEffect(() => {
    const isValidTourPathnames =
      !!tourPathnames &&
      Array.isArray(tourPathnames) &&
      tourPathnames?.length > 0;

    const isIncludedPathname =
      isValidTourPathnames && tourPathnames?.includes(pathname);

    const isShouldStopTour = isValidTourPathnames && !isIncludedPathname;
    if (isShouldStopTour) {
      const timeout = setTimeout(() => {
        setGuidedTourState(defaultState);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [tourPathnames, pathname]);

  return (
    <GuidedTourContext.Provider value={providerValue}>
      <ReactJoyride
        // steps={[{placement: "", locale: {}}]}
        steps={steps}
        run={isRunning}
        stepIndex={stepIndex}
        callback={handleCallback}
        {...props}
      />
      {children}
    </GuidedTourContext.Provider>
  );
};

export const useGuidedTour = (
  args = {
    steps: [],
    props: {},
    callback: null,
    stepIndex: 0,
    tourPathnames: [],
  }
) => {
  const {
    steps = [],
    props = {},
    callback = null,
    stepIndex = 0,
    tourPathnames = [],
  } = args ?? {};

  const { data, startTour, stopTour } = useContext(GuidedTourContext);

  const handleStartTour = () => {
    startTour({ steps, props, callback, stepIndex, tourPathnames });
  };

  return { data, startTour: handleStartTour, stopTour };
};

export default GuidedTourProvider;
