import { ToasterHook } from "../../contexts/ToasterContext";
import { fetch, useMutation } from "../../tools/api";

export const useMoveBalance = ({ afterSuccess }) => {
  const { mutation, result, loading } = useMutation({
    url: "/business_internal_transfers",
    method: "post",
    afterSuccess,
  });

  return { mutation, result, loading };
};

export const useFetchCompanyBalance = () => {
  const {
    data: companyBalance,
    refetch,
    loading,
  } = fetch({
    url: "/my_company_balance",
    method: "GET",
    formatter: ({ data }) => {
      return data;
    },
    woInit: false,
  });

  const { disbursement_balance } = companyBalance || {};
  const vaPocketAmount = (disbursement_balance || [])[0]?.balance;
  const danamonBalance =
    companyBalance?.danamon_account_details?.balance?.current_balance;
  const danamonAccountName =
    companyBalance?.danamon_account_details?.account?.account_holder_name;
  const danamonAccountNum =
    companyBalance?.danamon_account_details?.account?.account_number;

  return {
    vaPocketBalance: vaPocketAmount,
    danamonBalance,
    danamonAccountName,
    danamonAccountNum,
    refetch,
    loading,
  };
};
