import { useEffect, useState } from "react";
import { useModalHook } from "../../../../components/Modals";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import { useMutation } from "../../../../tools/api";
import { TransferServiceModal } from "../transferServiceModal/parent";
import { useBooleanDanamonProduct } from "./boolean";
import {
  ArraySourceOfPayment,
  CardPayment,
  SourceOfPaymentModal,
} from "./sourceOfPayment";

export const SourceOfPaymentParent = ({
  data, // optional, object (single) or array (batch)
  detailData, // payload => { totalPaymentRaw }
  isMoveBalance,
  ...propsTransferService // payload => { activeTransferBalance, setActiveTransferBalance }
}) => {
  // dataDetails => { totalPaymentRaw }

  // props banking
  const { activeTransferBalance } = propsTransferService || {};
  const balance = activeTransferBalance?.balance;
  const title = isMoveBalance ? "Balance Destination" : "Pay With";
  const emptyDesc = isMoveBalance
    ? "Select balance destination"
    : "Select a payment method";

  const { totalPaymentRaw } = detailData ?? {};
  const remainingBalance = +balance - totalPaymentRaw; // totalPaymentRaw;
  const isInsufficientWithFee = remainingBalance < 0;

  // toggle
  const { isOpen: isOpenSourcePayment, toggle: toggleSourcePayment } =
    useModalHook();

  const { array: arrayOption, loadingBalance, refetchBalance } = ArraySourceOfPayment({
    data,
    detailData,
    propsTransferService,
  });

  const { isBankTransfer, isSelectedBalance } = useBooleanDanamonProduct(
    activeTransferBalance?.value
  );

  const SourcePaymentDescDecider = () => {
    if (isSelectedBalance) {
      if (isInsufficientWithFee && !isMoveBalance)
        return `${activeTransferBalance?.title} : Insufficient`;
      if (isBankTransfer) return activeTransferBalance?.title;
      return `${activeTransferBalance?.title}: IDR ${formatCurrencyNoDecimal(
        activeTransferBalance?.balance
      )}`;
    }
  };

  return (
    <div style={{ position: "relative", zIndex: 0 }}>
      <CardPayment
        title={title}
        emptyDesc={emptyDesc}
        desc={SourcePaymentDescDecider()}
        icon={activeTransferBalance?.icon}
        styleContainer={{
          marginBottom: 32,
          position: "relative",
          zIndex: 0,
        }}
        onClick={toggleSourcePayment}
        isMoveBalance={isMoveBalance}
        isWarningDesc={isInsufficientWithFee}
      />
      <SourceOfPaymentModal
        isMoveBalance={isMoveBalance}
        data={data}
        totalAmount={totalPaymentRaw}
        array={arrayOption}
        loadingBalance={loadingBalance}
        refetchBalance={refetchBalance}
        toggle={toggleSourcePayment}
        isOpen={isOpenSourcePayment}
        {...propsTransferService}
      />
    </div>
  );
};

export const TransferServiceParent = ({
  data,
  isMultiRelease = false,
  customBanner = false,
  transferServiceRules,
  setTransferServiceRules = () => {},
  ...propsTransferService
}) => {
  // Props Banking
  const { taskId, activeTransferService } = propsTransferService || {};

  // Modal
  const { isOpen: isOpenTransferService, toggle: toggleTransferService } =
    useModalHook();

  // Fetch Option Rules
  const { mutation } = useMutation({
    url: "/danamon_transactions/transfer_services",
    afterSuccess: (res) => setTransferServiceRules(res?.data),
  });

  useEffect(() => {
    !!taskId?.length && mutation({ ids: taskId });
  }, []);

  return (
    <div style={{ position: "relative", zIndex: 0 }}>
      <CardPayment
        data={data}
        title="Transfer Service (Domestic)"
        emptyDesc="Select Transfer Service"
        emptyIcon="globe-outline"
        desc={activeTransferService?.title}
        icon={activeTransferService?.icon}
        onClick={toggleTransferService}
        customBanner={customBanner}
        {...propsTransferService}
      />
      <TransferServiceModal
        data={data}
        isMultiRelease={isMultiRelease}
        optionRules={transferServiceRules}
        toggle={toggleTransferService}
        isOpen={isOpenTransferService}
        {...propsTransferService}
      />
    </div>
  );
};
